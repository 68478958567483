.spinnerWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 19;
}

.diagramWrapper {
  display: flex;
}

.diagramWrapper:not(:first-child) {
  padding-top: 20px;
}

.legendWrapper {
  margin-left: 10px;
  min-width: 200px ;
}

.title {
  display: inline-block;
  color: var(--text);
  font-size: 22px;
  font-weight: 300;
  line-height: 48px;
  margin-bottom: 24px;
}

.mgChartWrap {
  position: relative;
        /* display:flex; */
}

.lineWrapper {
  position: relative;
  flex: 1;
  width: 100%;
}

.yAxisLine {
  pointer-events: none;
  position: absolute;
  border-top: 1px dashed var(--chart-line-backgroud);
  height: 2px;
  opacity: 0;
}

.xAxisLine {
  pointer-events: none;
  position: absolute;
  width: 2px;
  border-left: 1px dashed var(--chart-line-backgroud);
  opacity: 0;
}

.tooltip {
  position: absolute;
  background: rgba(255,255,255,0.8);
  border-radius: 4px;
  color: var(--dark-text);
  box-shadow: 0px 2px 6px rgba(43, 63, 103, 0.32);
  pointer-events: none;
  z-index: 2;
  font-size: 18px;
  line-height: 24px;
  width: max-content;
  transform: translate(-60%, 0);
}

.tooltipTable {
  margin: 0;
}

.legendWrap li {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #516175;
}

.diagramWrapper.columnWrapper {
  flex-direction: column;
}

.columnWrapper .legendWrapper {
  width: 100% !important;
  margin-bottom: 10px;
}

.columnWrapper .mgChartWrap {
  flex: 1;
  height: inherit;
  width: auto !important;
}


@media (orientation: portrait) {
  .diagramWrapper {
    flex-direction: column;
    height: 100px !important;
  }

  .mgChartWrap {
    flex-grow: 1;
    min-height: 40vh;
  }

  .legendWrapper {
    width: 100% !important;
  }
}

@media (max-width: 2800px) {
  .title {
    color: var(--text);
    font-size: 16px;
    font-weight: bold;
    line-height: 24px;
    margin-bottom: 14px;
  }
}
