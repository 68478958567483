.wrap {
  display: flex;
}

.form {
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 265px;
}

.formItem {
  max-width: 265px;
}

.formRulesText {
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
  margin-bottom: 0;
  margin-left: 36px;
  max-width: 400px;
}

@media (max-width: 576px) {
  .wrap {
    flex-wrap: wrap-reverse;
  }
  .formRulesText {
    margin: 0 0 18px 0;
  }
  .form {
    max-width: 265px;
    min-width: 220px;
  }
  .formItem {
    min-width: 220px;
  }
}
