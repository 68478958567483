.formWrap {
    display: flex;
}

.title {
    margin-bottom: 36px;
}

.formRulesText {
    font-size: 18px;
    line-height: 24px;
    color: var(--light-text);
    margin-bottom: 0;
    margin-left: 36px;
    max-width: 400px;
}

.stepCounterWrap {
    display: flex;
    height: 48px;
    max-width: 50%;
    margin-left: -70px;
    margin-bottom: 60px;
}

.stepCounterWrap .ant-steps-item-container {
    display: flex;
    height: 48px;
}

.successWrap {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 70px;
}

.successWrap h4 {
    font-weight: 300;
    font-size: 36px;
    line-height: 48px;
    color: var(--text-light-green-title)
}

.successWrap img {
    position: absolute;
    left: 0;
    top: 6px;
}

.step {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--step-bg);
    border-radius: 50%;
    font-size: 18px;
    line-height: 24px;
    color: var(--light-text);
    width: 48px;
    height: 48px;
    background-color: var(--step-bg);
    position: relative;
}

.activeStep {
    background-color: white;
    border-color: var(--gray-input-border);
}

.step .ant-steps-icon-dot {
    display: none;
}

@media (max-width: 576px) {
  .title {
    margin-bottom: 10px;
  }
  .successWrap {
    padding-left: 0;
    margin-top: 24px;
  }
  .successWrap img {
    display: none;
  }
  .successWrap h4 {
    margin: 0;
    font-size: 24px;
    line-height: 30px;
  }
}
