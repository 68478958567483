.mg-input-textarea__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /*max-width: 400px;*/
}

.mg-input-textarea {
    font-size: 18px;
    line-height: 36px;
    border: 1px solid var(--gray-input-border);
    height: 36px;
    color: var(--text);
}

.mg-input-textarea::placeholder {
    color:  var(--gray-input-placeholder);
}

.mg-input-textarea:hover {
    border-color: var(--green-text);
}