.companyName {
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: var(--dark-text);
    margin-bottom: 36px;
    text-align: start;
}

.button {
    max-width: 264px;
    position: absolute;
    right: 20px;
    bottom: 20px;
}

.tabsWrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.tabsText {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    margin: 0;
    position: absolute;
    top: 12px;
    left: 0;
    text-transform: uppercase;
    color: var(--text-light-gray);
    letter-spacing: 0.06em;
}