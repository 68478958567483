.cancelButton {
  display: none;
}

.okButton {
  margin: auto !important;
}

.tableSens td, .tableSens th {
  border: 1px solid #dddddd !important;
  text-align: left !important;
  padding: 8px;
}

.modalContent {
  padding-top: 60px;
}

.modalContent h3 {
  margin: 30px 0 25px;
  font-weight: 300;
  line-height: 48px;
  font-size: 36px;
}

.modalContent p {
  margin-bottom: 0;
}
