.mg-autocomplete__wrap .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    font-size: 18px;
    line-height: 36px;
    color: var(--text);
    border: 1px solid var(--gray-input-border);
    height: 36px;
    border-radius: 4px;
}

.mg-autocomplete__wrap {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
    max-width: 400px;
}

.mg-autocomplete__wrap .ant-select {
    font-size: 18px;
    line-height: 36px;
    color: var(--text);
    width: 100% !important;
}

.mg-autocomplete__wrap .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-color: var(--green-text);
    box-shadow: none;
}

.mg-autocomplete__wrap .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)~.mg-autocomplete-close {
    visibility: visible;
    opacity: 1;
}

.mg-autocomplete-close {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 17px;
    width: auto;
    min-height: unset;
    padding: 0;
    visibility: hidden;
    opacity: 0;
    fill: var(--green-text);
    transition: opacity 0.25s ease, visibility 0.25s ease, fill 0.25s ease;
}

.mg-autocomplete-close:hover {
    fill: var(--dark-text);
}

.mg-autocomplete-close:after {
    display: none;
}

.mg-autocomplete-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background: var(--light-green);
    border-radius: 4px;
    color: var(--light-text);
}

.mg-autocomplete-dropdown .ant-select-item {
    color: var(--light-text);
    font-size: 18px;
    line-height: 24px;
    padding: 6px 12px;
    font-weight: normal;
    max-height: 36px;
    background-color: white;
}

.mg-autocomplete-dropdown .ant-select-item-option-content {
    color: var(--light-text);
    font-size: 18px;
    line-height: 24px;
    max-height: 36px;
    display: flex;
    align-items: center;
    height: 100%;
}

.mg-autocomplete-dropdown {
    box-shadow: 0px 2px 4px rgba(11, 44, 84, 0.06), 0px 4px 6px rgba(11, 44, 84, 0.18);
    border-radius: 4px;
}

.colored-result {
    color: var(--green-text);
}