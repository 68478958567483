.page {
  display: flex;
  gap: 30px;
  width: 100%;
}

.menu {
  min-width: 330px;
  width: 15%;
}

.treeData {
  position: sticky;
  top: 110px;
  padding-right: 5px;
  padding-bottom: 10px;
  max-height: calc(100vh - 110px);
  overflow-y: auto;
  overflow-x: hidden;
  cursor: pointer;
  margin-bottom: 2em;
  font-weight: 700;
  font-size: 16px;
  line-height: 26px;
  color: var(--text-light-gray);
}

.menuItem {
  list-style-type: none;
  margin-left: -30px;
}

.menuItem > a {
  cursor: pointer;
  display: inline-block;
  margin-bottom: 1em;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: var(--text-light-gray);
}

.menuItem > a:hover, .selectedMenuItem > a {
  color: var(--black-text);
}

.items h2{
  color: var(--green-text);
  margin: 10px 0 0 0;
  font-size: 19px;
  font-weight: bold;
}

.items a {
  color: var(--black-text);
  text-decoration: underline;
}

.items a:hover{
  color: var(--text-light-gray);
}
.items {
  margin: 50px 100px 0 0;
  font-size: 17px;
}
