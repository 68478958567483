.mg-input-checkbox__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 400px;
}

.mg-checkbox-input .ant-checkbox-inner {
    border-color: var(--green-text);
}

.mg-checkbox-input .ant-checkbox+span {
    color: var(--light-text);
    font-size: 18px;
    line-height: 24px;
    margin-left: 11px;
    padding: 0;
}

.mg-checkbox-input .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--green-text);
    border-color: var(--green-text);
}