.button {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 0;
}

.button svg {
    transition: fill .25s ease;
    fill: #A3BBC6;
}

.button:hover svg {
    fill: var(--dark-text);
}

.button.visible svg {
    fill: var(--dark-text);
}

.button:after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
}

.deleteAlert {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    width: 100%;
    justify-content: flex-start;
    padding: 0;
    line-height: 24px;
    color: var(--text-light-gray) !important;
}

.deleteAlert:hover {
    color: var(--dark-text) !important;
    justify-content: flex-start;
}

.deleteAlert:hover svg {
    fill: var(--dark-text) !important;
}

.deleteAlert svg {
    margin-right: 9px;
    transition: fill .25s ease;
    fill: var(--text-light-gray);
}

.addPrice {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 16px;
    padding: 0;
    line-height: 24px;
    color: var(--green-text) !important;
}

.addPrice:hover {
    color: var(--dark-text) !important;
}

.addPrice:hover svg {
    fill: var(--dark-text) !important;
}

.addPrice svg {
    margin-right: 9px;
    transition: fill .25s ease;
    fill: var(--green-text);
}

.dropWown {
    box-shadow: 0px 2px 6px rgba(43, 63, 103, 0.32);
    border-radius: 4px;
    border: 2px solid #C5D0DD;
}