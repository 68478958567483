.mg-input-checkbox__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 400px;
}

.mg-input-radio__wrap .ant-radio-inner {
    border-color: var(--green);
    border-width: 2px;
}

.mg-input-radio__wrap .ant-radio-inner:after {
   background-color: var(--green);
}

.mg-input-radio__wrap .ant-radio-wrapper:hover .ant-radio {
    border-color: var(--green);
}

.mg-input-radio__wrap .ant-radio-checked::after {
    border: 1px solid var(--green)!important;
}

.mg-input-radio__wrap .ant-radio:hover .ant-radio-inner {
    border-color: var(--green);
}