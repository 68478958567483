.invite .ant-table-thead th:first-child .mg-table-th__title {
  display: none;
}

.invite .ant-table-tbody td:first-child {
  text-align: center;
  color: var(--light-text);
}

.invite .ant-table-thead th:first-child {
  width: 62px;
}

.invite .ant-table-thead th:first-child {
  width: 62px;
}

.invite .ant-table-tbody td:first-child,
.invite .ant-table-tbody td:last-child {
  border: 0;
  color: var(--light-text);
}

.invite .mg-input__wrap {
  margin-bottom: 0;
}
