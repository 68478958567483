.company-nav .ant-anchor-ink,
.company-nav .ant-anchor-ink::before {
  display: none;
}

.company-nav .ant-anchor-link {
  padding: 0;
}

.ant-anchor-ink {
  display: none;
}

.ant-menu-overflow-item.ant-menu-overflow-item-rest.ant-menu-submenu.ant-menu-submenu-horizontal {
  margin-top: 0 !important;
  margin-bottom: -15px;
}

.ant-menu-submenu-popup .ant-menu-item {
  padding-left: 18px !important;
}

.mg-input__wrap {
  margin-bottom: 0;
}
