.tabs-alerts .ant-tabs>.ant-tabs-nav:before {
    border: none;
}

.tabs-alerts .ant-tabs-nav {
    padding-left: 185px;
    margin: 0 106px 0 106px;
}

.tabs-alerts-modal .ant-tabs-nav {
  padding-left: 230px;
}

.tabs-alerts-modal .ant-tabs-nav-list {
   justify-content: flex-end;
}
.tabs-alerts-modal .ant-tabs {
    width: 100%;
}

.tabs-alerts .ant-tabs-ink-bar {
    background: var(--dark-text);
}

.tabs-alerts .ant-tabs-tab {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-light-gray);
    padding: 6px 36px;
    border-radius: 4px 4px 0px 0px;
    letter-spacing: 0.06em;
    margin: 0;
}

.tabs-alerts .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
}

.tabs-alerts .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--dark-text);
}

.tabs-alerts .ant-tabs-tab.ant-tabs-tab-active {
    background: var(--gray-bg);
}

.tabs-alerts .ant-tabs-content-holder {
    margin-top: 68px;
    position: relative;
}

.tabs-alerts .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
 }

.tabs-alerts-modal .ant-tabs-ink-bar {
    background: var(--dark-text);
}

.tabs-alerts-modal .ant-tabs-tab {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-light-gray);
    padding: 6px 36px;
    border-radius: 4px 4px 0px 0px;
    letter-spacing: 0.06em;
    margin: 0;
}

.tabs-alerts-modal .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
}

.tabs-alerts-modal .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--dark-text);
}

.tabs-alerts-modal .ant-tabs-tab.ant-tabs-tab-active {
    background: var(--gray-bg);
}

.tabs-alerts-modal .ant-tabs-content-holder {
    /* margin-top: 36px; */
    position: relative;
}

.tabs-alerts-modal .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
   height: 4px;
}

.tabs-alerts {
    font-family: 'Dubai';
}

.tabs-alerts .ant-tabs {
    width: 100%;
}

.rc-virtual-list-scrollbar-thumb {
    background: var(--pagination-disabled)!important;
}

.rc-virtual-list-scrollbar {
    width: 4px !important;
}

.set-alert .scroll-container .ps__thumb-y {
  width: 12px !important;
  margin-right: -1px;
  background-color: #C4D3DB;
}

.set-alert .scroll-container .ps__rail-y {
  width: 18px;
  right: 0;
  background-color: #C4D3DB;
  border-radius: 4px;
  opacity: 1 !important;
}

.set-alert .scroll-container .ps__rail-y:hover > .ps__thumb-y,
.set-alert .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #C4D3DB;
}

.set-alert .scroll-container .ps__rail-y,
.set-alert .scroll-container .ps__rail-x {
  background-color: white !important;
}

.set-alert .scroll-container .ps__thumb-x {
  height: 14px !important;
  margin-bottom: 1px;
}

.set-alert .scroll-container .ps__rail-x {
  height: 18px !important;
}

.set-alert .ant-table-body {
  overflow-y: hidden !important;
}

.set-alert table {
  table-layout: auto !important;
}

.set-alert .scroll-container .ps__thumb-x,
.set-alert .scroll-container .ps__rail-x {
  display: none;
}

@media (max-width: 768px) {
  .tabs-alerts .ant-tabs-nav {
    margin: 0 53px 0 53px;
  }
}

@media (max-width: 540px) {
  .set-alert .scroll-container .ps__thumb-x,
  .set-alert .scroll-container .ps__rail-x {
    display: block;
  }

  .set-alert._position .ant-table-container table > thead > tr:first-child th:first-child {
    display: none;
  }

  .set-alert .mg-table__wrap .mg-table .ant-table-thead th {
    padding: 0 !important;
  }

  .set-alert .ant-table-tbody tr:nth-child(2) td.ant-table-cell {
    padding-top: 24px !important;
  }
}

@media (max-width: 395px) {
  .tabs-alerts .ant-tabs-nav {
    margin: 0 20px 0 20px;
  }
}
