.supportForm {
  width: 50%;
}

@media (max-width: 768px) {
  .supportForm {
    width: 70%;
  }
}

@media (max-width: 540px) {
  .supportForm {
    width: 100%;
  }
}
