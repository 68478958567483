.mg-swith-input.ant-switch {
    background-color: transparent;
    border: 3px solid var(--green-text);
    min-width: 22px;
    width: 22px;
    height: 13px;
    transition: background-color .25s ease;
}

.mg-swith-input.ant-switch:focus { 
    box-shadow: none;
}

.mg-swith-input.ant-switch-checked {
    background-color: var(--green-text);
}

.mg-swith-input .ant-switch-handle {
    width: 5px;
    height: 5px;
    top: 15.5%;
}

.mg-swith-input .ant-switch-handle::before {
    background-color: var(--green-text);
    transition: background-color .25s ease;
}

.mg-swith-input.ant-switch-checked .ant-switch-handle::before {
    background-color: white;
}

.mg-swith-input.ant-switch-checked .ant-switch-handle {
    left: unset;
    right: 2px;
}