.title {
    margin-bottom: 24px;
    color: var(--yellow);
    display: flex;
    align-items: center;
}

.title svg {
    margin-left: 27px;
    margin-bottom: 4px;
}

.divider {
    background-color: var(--divider-background);
    margin-top: 0;
    margin-bottom: 24px;
}

.form {
    display: flex;
    flex-direction: column;
    width: fit-content;
    min-width: 400px;
}

.formItem {
    max-width: 400px;
    display: flex;
}

.formItem .ant-form-item-control-input-content {
    display: flex;
}

.formWrap {
    display: flex;
    margin-bottom: 48px;
}

.formText {
    max-width: 616px;
    margin-left: 36px;
    font-size: 18px;
    line-height: 24px;
    color: #5F7794;
}

.tabsWrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.tabsText {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    margin: 0;
    position: absolute;
    top: 12px;
    left: 106px;
    text-transform: uppercase;
    color: var(--text-light-gray);
    letter-spacing: 0.06em;
}

.dividerTabs {
    position: absolute;
    top: 35px;
    left: 106px;
    width: calc(100% - 212px);
    min-width: calc(100% - 212px);
}

.tableColumnsText {
    position: absolute;
    top: -50px;
    right: 166px;
    font-size: 18px;
    line-height: 24px;
    color: var(--text-light-gray);
    border-bottom: 1px solid #C1CFDB;
    padding-left: 24px;
    width: 18%;
}

.tableColumnsTextPrice {
    position: absolute;
    top: -50px;
    right: 166px;
    font-size: 18px;
    line-height: 24px;
    color: var(--text-light-gray);
    border-bottom: 1px solid #C1CFDB;
    padding-left: 24px;
    width: 36%;
}

.yellowText {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    display: flex;
    text-transform: uppercase;
    width: auto;
    letter-spacing: 0.06em;
    margin: 0;
    white-space: nowrap;
    color: var(--yellow);
}

.page {
    position: relative;
}

.paddings {
    padding: 60px 106px 0 106px;
}

@media (max-width: 950px) {
  .formWrap {
    flex-wrap: wrap-reverse;
  }
  .form {
    max-width: 400px;
  }
  .formText {
    margin-left: 0;
  }
}

@media (max-width: 768px) {
  .paddings {
    padding: 30px 53px 0 53px;
  }
  .tableColumnsText,
  .tableColumnsTextPrice {
    right: 153px;
  }
  .tabsText, .dividerTabs {
    left: 53px;
  }
}

@media (max-width: 576px) {
  .form {
    max-width: 300px;
    min-width: 250px;
  }
  .formItem {
    max-width: 300px;
    min-width: 250px;
  }
  .tableColumnsText,
  .tableColumnsTextPrice {
    top: -60px;
    width: 50%;
    right: 160px;
  }
}

@media (max-width: 395px) {
  .paddings {
    padding: 30px 20px 0 20px;
  }
  .tabsText, .dividerTabs {
    left: 20px;
  }
}
