.mg-btn {
    background: var(--green-text);
    border-radius: 4px;
    min-height: 36px;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    width: 100%;
    padding: 6px 36px;
    letter-spacing: 0.06em;
    border: none;
    max-width: 300px;
    text-transform: uppercase;
    transition: color .25s ease, background-color .25s;
}

.disabled {
    opacity: 0.6;
    pointer-events: none;
    cursor: not-allowed;
  }

.mg-btn._big {
    height: 48px;
}

.mg-btn:disabled {
    background-color: var(--button-disabled) !important;
    color: #fff;
}

.mg-btn:disabled:hover {
    background-color: var(--button-disabled) !important;
    color: #fff;
}

.mg-btn:hover {
    background: #0a716c;
    color: white;
    border: none;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.mg-btn:focus {
    background: var(--green-text);
    color: white;
    border: none;
}

.mg-btn:active {
    background: var(--green-text);
    color: white;
    border: none;
}

.mg-btn._green {
    max-width: 380px;
    background: var(--green);
    border-radius: 4px;
}

.mg-btn._green-border {
    border: 1px solid var(--green);
    color: var(--green);
    background-color: white;
}

.mg-btn._green-border:hover {
    color: white;
    background-color: rgb(213, 7, 7);
}

.mg-btn._green:hover {
    background: var(--dark-text);
    color: white;
}

.mg-btn._green:disabled {
    background: var(--green-disabled);
    color: white;
}

.mg-btn._green:disabled:hover {
    background: var(--green-disabled);
    color: white;
}

.mg-btn._green:focus {
    background: var(--dark-text);
    color: white;
}

.mg-btn._green:active {
    background: var(--dark-text);
    color: white;
}

.mg-btn._green-light {
    color: var(--text-medium-blue);
    background: var(--green-light);
}


.mg-btn._green-light:hover {
    color: var(--text-medium-blue);
    background: white;
}


.mg-btn._green-light:disabled {
    color: var(--text-medium-blue-disabled);
    background: var(--green-light-disabled);
}

.mg-btn._text {
    background: transparent;
    color: var(--green-text);
    outline: none;
    box-shadow: none;
}

.mg-btn._text::after {
  display: none;
}

.mg-btn._blue-border {
  color: var(--text-bright-green);
  border: 1px solid var(--text-bright-green);
  background-color: #fff;
}

.mg-btn._blue-border:hover {
  background: var(--dark-text);
  color: white;
}

.mg-btn._blue-border:focus {
  color: var(--text-bright-green);
  border: 1px solid var(--text-bright-green);
  background-color: #fff;
}

.mg-btn._blue-border:active {
  color: var(--text-bright-green);
  border: 1px solid var(--text-bright-green);
  background-color: #fff;
}

.mg-btn._yellow {
    color: #fff;
    background-color: var(--yellow);
}

.mg-btn._red {
    color: #fff;
    background-color: var(--error);
}
