.mg-table__wrap .mg-table-th {
  display: flex;
  align-items: center;
  color: var(--light-text);
}

.mg-table__wrap .mg-table-th__title {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: var(--light-text);
}

.mg-table__wrap .mg-table-th__title._active {
  color: var(--green);
}

.mg-table__wrap .mg-table-th__title::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 5px);
  height: calc(100% + 5px);
  content: '';
}

.mg-table__wrap .mg-table .ant-table-thead th {
  padding-top: 26px !important;
  background-color: transparent !important;
  border: 0;
}

.mg-table__wrap .mg-table-th__title {
  font-weight: normal;
  color: var(--light-text);
}

.mg-table__wrap .mg-table .ant-table-cell {
  padding: 6px 24px !important;
  border: 2px solid #fff !important;
  background-color: var(--gray-bg) !important;
}

.mg-table__wrap .ant-table-cell::before {
  display: none;
}

.mg-table__wrap .mg-table .ant-table-cell.ant-table-cell-row-hover {
  background-color: var(--gray-bg) !important;
}

.mg-table__wrap .ant-table-content,
.mg-table__wrap .mg-table-th,
.mg-table__wrap .ant-table-tbody td {
  font-size: 18px;
  line-height: 36px;
}

.mg-table__wrap .ant-table-tbody td {
  color: var(--dark-text);
}

.ant-table-tbody td:first-child,
.ant-table-tbody td:last-child {
  border: 0;
}

.ant-pagination-prev,
.ant-pagination-item,
.ant-pagination-next {
  width: 36px;
  height: 36px;
  margin-right: 12px;
}

.ant-pagination-item,
.ant-pagination-item-link {
  border: 0 !important;
  border-radius: 4px !important;
  background-color: var(--step-bg) !important;
}

.ant-pagination-item-active {
  background-color: #fff !important;
  border: 2px solid var(--green-text) !important;
}

.ant-pagination-item-active a {
  color: var(--green-text) !important;
}

.ant-pagination-item a {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Dubai;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
}

.ant-pagination-item a:hover {
  color: var(--light-text);
}

.ant-pagination-item-link svg {
  color: var(--light-text);
}

.ant-pagination-disabled .ant-pagination-item-link svg {
  color: var(--pagination-disabled);
}

.mg-table__pagination-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 0 120px 0;
  background-color: var(--gray-bg);
  /* margin-top: 72px; */
}

.mg-table__wrap {
  z-index: 2;
}

.mg-table__wrap._white-row .mg-table .ant-table-thead th {
  padding: 20px 12px 12px 12px !important;
  background-color: var(--gray-bg) !important;
  border: 0 !important;
  margin: 0;
  border-bottom: 1px solid var(--chart-line-backgroud) !important;
}

.mg-table__wrap._white-row .mg-table .ant-table-cell {
  padding: 12px !important;
  border: 0 !important;
  border-bottom: 1px solid var(--chart-line-backgroud) !important;
  background-color: white !important;
  margin: 0 !important;
}

.mg-table__wrap._white-row .mg-table .ant-table-row:hover .ant-table-cell {
  background-color: var(--gray-bg) !important;
}

.mg-table__wrap._white-row .mg-table .ant-table-thead {
  position: relative;
}

.mg-table__wrap .ant-table-tbody .ant-table-row {
  cursor: pointer;
}

@media (max-width: 576px) {
  .mg-table__pagination-wrap {
    padding: 48px 0 60px 0 !important;
  }
}
