.tabs-wrap-products .ant-tabs>.ant-tabs-nav:before {
  border: none;
}

.tabs-wrap-products .ant-tabs-nav {
  padding-left: 0;
  margin: 0;
}

.tabs-wrap-products-modal .ant-tabs-nav {
  padding-left: calc(100% - 415px);
}

.tabs-wrap-products-modal .ant-tabs-nav-list {
  justify-content: flex-end;
}

.tabs-wrap-products-modal .ant-tabs {
  width: 100%;
}

.tabs-wrap-products .ant-tabs-ink-bar {
  background: var(--dark-text);
}

.tabs-wrap-products .ant-tabs-tab {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-light-gray);
  padding: 6px 36px;
  border-radius: 4px 4px 0px 0px;
  letter-spacing: 0.06em;
  margin: 0;
}

.tabs-wrap-products .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
}

.tabs-wrap-products .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--dark-text);
}

.tabs-wrap-products .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--gray-bg);
}

.tabs-wrap-products .ant-tabs-content-holder {
  position: relative;
}

.tabs-wrap-products .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
  height: 4px;
}

.tabs-wrap-products-modal .ant-tabs-ink-bar {
  background: var(--dark-text);
}

.tabs-wrap-products-modal .ant-tabs-tab {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-light-gray);
  padding: 6px 36px;
  border-radius: 4px 4px 0px 0px;
  letter-spacing: 0.06em;
  margin: 0;
}

.tabs-wrap-products-modal .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
}

.tabs-wrap-products-modal .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--dark-text);
}

.tabs-wrap-products-modal .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--gray-bg);
}

.tabs-wrap-products-modal .ant-tabs-content-holder {
  margin-top: 36px;
  position: relative;
}

.tabs-wrap-products-modal .ant-tabs-top>.ant-tabs-nav .ant-tabs-ink-bar {
  height: 4px;
}

.tabs-wrap-products .ant-tabs {
  width: 100%;
}

.rc-virtual-list-scrollbar-thumb {
  background: var(--pagination-disabled) !important;
}

.rc-virtual-list-scrollbar {
  width: 4px !important;
}

.tabs-wrap-products .ant-tabs-nav-wrap {
  padding: 0 106px;
}

.tabs-wrap-products .ant-table-row:hover {
  cursor: pointer;
}

.filterPanel .mg-input__wrap {
  margin-bottom: 0;
}

@media (max-width: 950px) {
  .tabs-wrap-products .ant-tabs-nav {
    margin-bottom: 42px;
  }
}

@media (max-width: 768px) {
  .tabs-wrap-products .ant-tabs-nav-wrap {
    padding: 0 53px;
  }
}

@media (max-width: 576px) {
  .tabs-wrap-products .ant-tabs-tab {
    padding: 6px 20px;
  }
}

@media (max-width: 395px) {
  .tabs-wrap-products .ant-tabs-nav-wrap {
    padding: 0;
  }
}
