.wrapHeader {
  background-color: var(--gray-bg);
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 24px 106px;
}

.header > *:not(:last-child) {
  margin-right: 24px;
}

.backBtn {
  width: fit-content;
  padding: 0;
  margin-right: 36px;
  color: var(--green-text) !important;
  font-size: 12px;
  line-height: 24px;
  box-shadow: none;
}

.backBtn svg {
  margin-right: 10px;
}

.backBtn:hover {
  color: var(--text-dark-green) !important;
  font-size: 12px;
  line-height: 24px;
}

.titleSub {
  margin-right: 36px;
  color: var(--dark-text);
  font-size: 20px;
  line-height: 36px;
}

.totalMembers {
  display: flex;
  margin-left: auto;
  color: var(--text-light-gray);
  font-size: 20px;
  line-height: 36px;
}

.totalMembers > *:not(:last-child) {
  margin-right: 50px;
}

.deleteText {
  cursor: pointer;
}

.deleteText:hover {
  color: var(--warning);
}

.content {
  margin: 0 70px 70px 0;
  height: 100%;
}

.searchMembers {
  flex: 1;
  max-width: 350px;
}

.membersContent {
  height: fit-content;
  margin-top: 24px;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 80px;
}

.scrollContainer {
  max-height: 450px;
}

.memberItem:not(:last-of-type) {
  margin-bottom: 6px;
}

.memberItem {
  background-color: var(--subscribers-background);
  color: #5f7794;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 48px;
  padding: 12px 14px 12px 24px;
}

.memberItem .deleteIcon {
  color: var(--table-icons);
  margin-left: 10px;
  transition: color 0.25s ease;
}

.memberItem .deleteIcon:hover {
  color: var(--dark-text);
}

.contentWrap {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-top: 36px;
  padding: 36px 106px 0 106px;
}

.members,
.content {
  flex: 1;
  min-width: 540px;
  max-width: 540px;
}

.membersTabs {
  width: 340px;
  height: 36px;
  border-radius: 4px;
  display: flex;
  margin-bottom: 30px;
}

.membersTab {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  flex: 1;
  border-radius: 4px;
  background-color: var(--subscribers-background);
  color: #5f7794;
  z-index: 1;
}

.membersTab:first-of-type {
  margin-right: -5px;
}

.membersTabActive {
  background-color: var(--green-text);
  color: white;
  z-index: 2;
}

.addUserRow {
  display: flex;
  flex-wrap: wrap;
}

.addUserBtn {
  width: fit-content;
  padding: 0;
  margin-left: 70px;
  color: var(--green-text) !important;
  font-size: 16px;
  line-height: 24px;
}

.addUserBtn:hover {
  color: var(--text-dark-green) !important;
}

.addQuestionBtn {
  text-transform: uppercase;
  color: var(--green-text);
  font-size: 16px;
  font-weight: 500;
  border: none;
  padding: 0;
  margin: 15px 0;
}

.addQuestionBtn:focus {
  color: var(--green-text);
}

.saveBtn {
  margin: 70px 0;
}

.pollNameInput {
  flex: 1;
  max-width: 600px;
  margin-bottom: 0;
}

@media (max-width: 1370px) {
  .saveBtn {
    margin-top: 20px;
  }
}

@media (max-width: 1150px) {
  .members {
    padding: 0;
    margin-bottom: 50px;
  }
  .content {
    margin-right: 0;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 24px 53px;
    flex-wrap: wrap;
  }
  .contentWrap {
    padding: 36px 53px;
  }

  .backBtn {
    order: 1;
  }

  .totalMembers {
    order: 2;
  }

  .pollNameInput {
    order: 3;
    min-width: 60%;
    margin-right: 0z;
  }
}

@media (max-width: 600px) {
  .members,
  .content {
    min-width: initial;
    flex: auto;
  }

  .addUserBtn {
    margin-left: 20px;
  }
}

@media (max-width: 395px) {
  .header {
    padding: 24px 20px;
  }
  .contentWrap {
    padding: 36px 20px;
  }
}

@media (max-width: 340px) {
  .saveBtn {
    width: 270px;
  }
}
