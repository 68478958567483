.mg-input__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 400px;
}

.mg-input-number {
    width: 100px;
    font-size: 18px;
    line-height: 36px;
    border: 1px solid var(--gray-input-border);
    height: 36px;
    color: var(--text);
}
.mg-input-number::placeholder {
    color:  var(--gray-input-placeholder);
}

.mg-input-number:hover {
    border-color: var(--green-text);
}

.mg-input-number:invalid {
    border: 1px solid var(--error);
}

.mg-input-number.invalid {
  border: 1px solid var(--error);
}

.ant-input-number-focused.invalid {
  box-shadow: 0 0 0 2px rgba(255, 74, 24, 0.2);
}
