.form {
  display: flex;
  flex-direction: column;
  max-width: 265px;
  margin-right: 36px;
}

.formItem {
  max-width: 265px;
}
