.divider {
  background-color: var(--divider-background);
  margin-top: 0;
  margin-bottom: 24px;
}

.formWrap {
  width: 100%;
  margin-bottom: 36px;
}

.input {
  max-width: 100%;
}

.titleModal {
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  margin: 0;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding-top: 48px;
  color: var(--text-light-gray);
}

.collapseHeader {
  display: flex;
  width: 100%;
  border-radius: 4px;
}

.formItem {
  margin-bottom: 0;
}

.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  line-height: 24px;
  width: 100%;
  color: var(--light-text);
  background-color: var(--gray-bg);
  padding: 12px 24px;
  border-radius: 4px;
}

.title svg {
  margin-left: 21px;
  fill: var(--light-text);
  margin-top: -5px;
}


.title svg:last-child {
  transform: rotate(180deg);
  margin-left: 14px;
}

.openTitle svg {
  fill: var(--dark-text);
}

.openTitle {
  color: var(--dark-text);
  background-color: var(--light-gray-2);
}

.openTitle svg:last-child {
  transform: rotate(0);
}

.resultsWrap {
  display: flex;
  flex-direction: column;
  max-height: 250px;
  padding-right: 20px;
}

.checkboxWrap {
  display: grid;
  grid-template-rows: repeat(2, 20px);
  gap: 17px;
  grid-auto-flow: column;
}

.resultItem {
  display: flex;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background-color: var(--gray-bg);
  border-radius: 4px;
  margin-bottom: 6px;
}

.titleColumn {
max-width: 620px;
text-align: left;
}
