.pointInput {
  position: absolute;
  z-index: 2;
  padding: 20px;
  width: 280px;
  background-color: #fff;
  box-shadow: 0px 2px 6px rgba(43, 63, 103, 0.32);
}

.hidden {
  display: none;
}

.title {
  margin-bottom: 14px;
  color: #5F7794;
  font-size: 18px;
  line-height: 24px;
}

.inputWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.inputWrapper div {
  margin-bottom: 0;
}

.inputWrapper button {
  padding: 3px;
  min-height: auto;
  height: 34px;
  width: 34px;
  flex-shrink: 0;
}

.note {
  margin-bottom: 0;
  color: var(--gray-input-placeholder);
  font-size: 14px;
  line-height: 16px;
}

.note.invalid {
  color: var(--error);
}
