.page {
  position: relative;
  height: 100%;
}

.spinnerWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 19;
}

.title {
  font-size: 28px;
  padding: 10px 0 0 106px;
  margin: 0;
}


.companyLink {
  position: relative;
  color: var(--dark-text);
}

.companyLink:hover {
  color: var(--green-text);
}

.companyLink:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}

.deleteBtn {
  padding: 0;
  z-index: 10;
}

.deleteBtn::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: '';
}

.deleteBtn svg {
  transition: fill .25s ease;
  fill: #A3BBC6;
}

.deleteBtn:hover svg {
  fill: var(--dark-text);
}

.showLinesWrap {
  display: flex;
  justify-content: flex-end;
  padding: 0 90px 8px 0;
}

.showLines {
  display: flex;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  color: #8C9FAE;
  line-height: 24px;
  cursor: pointer;
}

.showLines span {
  margin-left: 16px;
  display: block;
  color: var(--green);
}

@media (max-width: 768px) {
  .title {
    padding: 60px 60px 0 60px;
  }
  .showLinesWrap {
    padding: 0 60px 8px 0;
  }
}

@media (max-width: 395px) {
  .title {
    padding: 60px 20px 0 20px;
  }

  .showLinesWrap {
    padding: 0 20px 8px 0;
  }
}
