.mgChartWrap {
    width: 100%;
    display: flex;
    cursor: pointer;
    margin-top: 15px;
}

.yAxisLine {
    position: absolute;
    border-top: 1px dashed var(--chart-line-backgroud);
    height: 2px;
    opacity: 0;
}

.xAxisLine {
    position: absolute;
    width: 2px;
    border-left: 1px dashed var(--chart-line-backgroud);
    opacity: 0;
}

.tooltip {
    position: absolute;
    background: white;
    border-radius: 4px;
    color: var(--dark-text);
    box-shadow: 0px 2px 6px rgba(43, 63, 103, 0.32);
    pointer-events: none;
    z-index: 2;
    font-size: 18px;
    line-height: 24px;
    width: fit-content;
    transform: translate(-50%, 0);
}

.tooltipTable {
    margin: 10;
}

.legendWrap li {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: #516175;
}