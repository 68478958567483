.chart-actions [id^='legend-container-'] ul {
    display: flex;
    flex-wrap: wrap;
    float: right;
    column-gap: 20px;
  }
  
  .chart-actions [id^='legend-container-'] ul li {
    font-size: 17px;
    cursor: default;
    margin-bottom: 0 !important;
  }
  