.createTariffForm .ant-form-item,
.createTariffForm .mg-input__wrap {
  margin-bottom: 0;
}

.createTariffForm .mg-input,
.createTariffForm .ant-form-item-explain-error {
  min-width: 602px;
}

@media (max-width: 930px) {
  .createTariffForm .mg-input,
  .createTariffForm .ant-form-item-explain-error {
    min-width: 400px;
  }
}

@media (max-width: 510px) {
  .createTariffForm .mg-input,
  .createTariffForm .ant-form-item-explain-error {
    min-width: 300px;
  }
}

@media (max-width: 340px) {
  .createTariffForm .mg-input,
  .createTariffForm .ant-form-item-explain-error {
    min-width: 270px;
  }
}
