.supportForm {
    flex: 1;
    min-width: none;
  }
  
  .formItem {
    width: 100% !important;
  }

  .formTextArea {
    width: 50% !important;
  }
.preview {
  margin: 0 0 22px 10px;
  width: 50%;
  min-height: 180px;
  font-size: 17px;
  border: 1px solid #00A0AF;
  border-radius: 5px;
  padding: 5px;
}

.inline {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}


.preview h1, h2, h3, h4, h5 h6{
  color: var(--green-text);
  margin: 10px 0 0 0;
  font-size: 19px;
  font-weight: bold;
}

.preview a {
  color: var(--black-text);
  text-decoration: underline;
}

.preview a:hover{
  color: var(--text-light-gray);
}

.btn{
  margin-left: 10px;
}
  