.orderWrap {
    position: relative;
    display: flex;
    width: 100%;
    width: 100%;
    padding: 14px 60px 14px 250px;
    background-color: var(--light-gray-2);
    align-items: center;
}

.sortItem {
    position: relative;
    color: var(--light-text);
    font-size: 18px;
    line-height: 24px;
    padding: 1px 36px 1px 12px;
    background-color: white;
    border-radius: 200px;
    margin-right: 12px;
    z-index: 2;
    white-space: nowrap;
    cursor: move;
}

.carousel {
    justify-content: flex-start;
    text-align: left;
    float: left;
    z-index: 2;
    width: 100%;
    display: flex;
    height: 100%;
}

.orderTitle {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    color: var(--text-light-gray);
    display: block;
    margin-right: 20px;
    white-space: nowrap;
    margin-top: 5px;
}

.buttonClose {
    position: absolute;
    top: -1px;
    right: 0px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    min-height: 30px;
    background-color: var(--light-gray-2);
}

.scrollVisible {
    margin-bottom: -20px;
}

.scrollVisible::after {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 20px;
    width: 100%;
    background-color: var(--light-gray-2);
    z-index: 1;
    content: '';
}

.buttonClose svg {
    width: 10px;
    height: 10px;
    transition: fill .25s ease;
    fill: var(--light-text);
}

.buttonClose:hover svg {
    fill: var(--green-text);
}

.sortList {
    width: calc(100% - 100px);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttonCarusel {
    position: relative;
    padding: 0 10px;
    min-height: 10px;
    height: 30px;
    width: 40px;
}

.buttonCaruselLeft svg {
    transform: rotate(180deg);
}

.buttonCarusel svg {
    transition: fill .25s ease;
    fill: var(--divider-background);
}

.buttonCarusel:hover svg {
    fill: #3894B1;
}

.scrollWrap {
    width: calc(100% - 100px);
    overflow-x: hidden;
}


@media (max-width: 576px) {
    .orderWrap {
        padding: 14px 24px 14px 40px;
    }    
}