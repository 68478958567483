@import 'antd/dist/antd.css';
@import './app/styles/page.css';
@import './app/styles/variables.css';
@import './app/styles/btn.css';
@import 'react-perfect-scrollbar/dist/css/styles.css';
@import './app/styles/modal.css';

.App {
  height: 100%;
  position: relative;
}
.customModal {
  position: sticky;
  top:0;
  z-index:10;
}
