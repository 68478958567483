.wrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
}

.message {
  margin-right: 16px;
  max-width: 470px;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
}

.rowGroup {
  display: flex;

}

.form {
  display: flex;
  flex-direction: column;
}

.email {
  margin-left: 12px;
}

.btn {
  min-height: 36px;
  max-width: 100%!important;
}

@media (max-width: 1200px) {
  .wrap {
    display: block;
    max-width: 405px;
  }
}

@media (max-width: 768px) {
  .form {
    max-width: 265px;
  }
  .formGroup {
    margin-left: 0;
  }
  .rowGroup {
    display: block;
  }
  .email {
    margin-left: 0;
  }
  .btn {
    margin-left: 0;
  }
  .wrap {
    margin-bottom: 18px;
  }
}
