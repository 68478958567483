.paddings {
  padding: 60px 106px 0 106px;
}

.title {
  color: var(--text-light-blue);
}

.actionsWrap {
  display: flex;
  width: 100%;
}

.actionsWrap svg,
.buttonAction svg {
  transition: fill 0.25s ease;
  fill: var(--table-icons);
}

.buttonAction {
  padding: 12px;
  margin-right: 10px;
  min-width: unset;
  width: auto;
}

.buttonAction:hover svg {
  fill: var(--dark-text);
}

.modalTextWrap {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.modalTextWrap span {
  color: var(--dark-text);
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 12px;
}

.addNotificationBtn,
.addPollBtn {
  margin-right: 50px;
  color: var(--green-text) !important;
}

.addNotificationBtn:hover,
.addPollBtn:hover {
  color: var(--text-dark-green) !important;
}

.topPanel {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  background-color: #e9ecee;
  padding: 9px 106px;
}

.topPanel:before {
  position: absolute;
  left: -106px;
  width: calc(100% + 106px);
  border-bottom: 1px solid var(--chart-line-backgroud) !important;
  background-color: var(--gray-bg) !important;
  content: '';
}

.showLines {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  color: #8c9fae;
  z-index: 2;
  cursor: pointer;
  line-height: 24px;
}

.search {
  min-width: 400px;
  margin-right: 12px;
}

.linesCount {
  margin-left: 16px;
  display: block;
  color: var(--green);
}

.filtersLine {
  display: flex;
  margin-right: 60px;
}

.filterBtn {
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: var(--text-light-gray) !important;
  min-width: unset;
  width: auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  padding: 0;
  min-height: auto;
  margin-right: 24px;
}

.filterBtn:hover {
  color: var(--dark-text) !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
}

.filterBtnSelected {
  color: var(--dark-text) !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
}

.filtersLineTitle {
  display: block;
  margin-right: 24px;
}

.buttonActionRed svg {
  fill: var(--error);
}

.rowsMenuItem {
  font-weight: 500;
  font-size: 12px;
  color: var(--green);
  line-height: 24px;
}

.rowsMenuItem:hover {
  color: var(--dark-text) !important;
}

.boldText {
  font-weight: 500;
}

.redText {
  font-weight: 700;
  color: var(--error);
}

.link:hover {
  color: var(--green-text);
  cursor: pointer;
  transition: color 0.25s ease;
}

@media (max-width: 776px) {
  .paddings {
    padding: 30px 60px 0 60px;
  }

  .topPanel {
    padding: 9px 60px;
  }

  .topPanel:before {
    left: -60px;
    width: calc(100% + 60px);
  }
}
