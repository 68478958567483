.PublicLayout {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content {
  margin-top: 96px;
  flex: 1 0 auto;
  background-color: white;
}

.footer {
  flex-shrink: 0;
  text-align: 'center';
}
