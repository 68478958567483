.ant-steps-horizontal {
  width: 360px;
  margin: 20px 0 60px -70px;
}

.ant-steps-horizontal.ant-steps-dot .ant-steps-item-container{
  height: 48px;
}

.ant-steps-horizontal.ant-steps-dot .ant-steps-item-tail {
  top: 50%;
}

.ant-steps-horizontal.ant-steps-dot .ant-steps-item-tail::after {
  margin-left: 17px;
  height: 2px;
  top: 50%;
  background-color: var(--step-bg)!important;
}

.ant-steps-vertical {
  width: 48px;
  height: 180px;
}

.ant-steps-vertical.ant-steps-dot .ant-steps-item .ant-steps-item-container .ant-steps-item-tail {
  left: 50%;
}

.ant-steps-vertical .ant-steps-item-tail::after {
  left: 50%;
  margin-left: 0;
  margin-top: 20px;
  width: 2px!important;
  background-color: var(--step-bg)!important;
}
