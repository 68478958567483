.mg-slider_wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.ant-slider {
  flex: 1;
}

.ant-slider-track {
  background-color: var(--green-text);
}

.ant-slider:hover .ant-slider-track {
  background-color: var(--green-text);
}

.ant-slider-handle {
  background-color: var(--green-text);
  border-color: var(--green-text);
}

.ant-slider-handle:focus {
  border-color: var(--green-text);
}

.ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
  background-color: var(--text-light-blue);
  border-color: var(--text-light-blue);
}
