.subscription {
  overflow: hidden;
  border-radius: 4px;
  margin-bottom: 24px;
  display: flex;
  width: 254px;
  min-height: 552px;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-right: 12px;
  padding: 24px 12px;
  cursor: pointer;
}

.subscriptionDisabled {
  cursor: not-allowed!important;
}

.subscriptionSelected {
  border: 3px solid var(--text-light-green-title);
}

.iconWrap {
  position: relative;
  height: 132px;
}

.subscription:last-child {
  margin-right: 0;
}

.subscription svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  fill: var(--subscriptions-icons-gray);
  transition: fill .25s ease;
}

.subscriptionIcon {
  height: 100%;
  min-width: 80px;
  min-height: 120px;
  background-repeat: no-repeat;
  background-position-y: 37px;
  background-position-x: center;
}

.subscriptionContent {
  height: 100%;
  width: 100%;
  padding: 12px 12px 24px 12px;
  cursor: pointer;
}

.subscriptionContentDisabled {
  cursor: not-allowed;
}

.subscriptionContent span {
  font-size: 16px;
  line-height: 24px;
  color: var(--light-text);
  display: block;
  margin: 12px 0;
}

.subscriptionContent h4 {
  margin-bottom: 25px;
  font-weight: bold;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--dark-text);
}

.subscriptionContent p {
  margin-bottom: 0;
  max-width: 900px;
  color: var(--dark-text);
  font-size: 18px;
  line-height: 32px;
}

.subscriptionContent ul {
  margin: 0;
  min-height: 300px;
  padding-left: 12px;
  color: var(--dark-text);
  font-size: 16px;
  line-height: 24px;
  list-style: none;
}

.subscriptionContent ul li {
  position: relative;
  margin-bottom: 16px;
}

.subscriptionContent ul li::before {
  position: absolute;
  top: 10px;
  left: -16px;
  width: 6px;
  height: 6px;
  background-color: var(--text-dark-green);
  border-radius: 50%;
  content: '';
}
