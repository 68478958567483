.wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.subscription {
  margin-right: 24px;
}

.period {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.period h3 {
  margin: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: var(--text-light-gray);
}

.divider {
  margin: 0;
  border-color: var(--text-light-gray);
}

.description {
  margin: 24px 0 18px 0;
  font-size: 18px;
  line-height: 24px;
  color: #5F7794;
}

.priceItem {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
  margin-bottom: 6px;
  font-size: 18px;
  line-height: 24px;
  color: var(--dark-text);
  border-radius: 4px;
  background-color: var(--gray-bg);
  cursor: pointer;
  transition: background-color .25s ease;
}

.priceItem:hover {
  background-color: var(--light-gray-2);
}

.priceDescription {
  display: flex;
}

.pricePaidPeriod {
  display: block;
  min-width: 80px;
  margin-right: 103px;
}

.priceCurrency {
  min-width: 60px;
  margin-right: 24px;
}

.payment {
  margin-top: 30px;
  align-self: flex-end;
}

.payment p {
  margin-bottom: 12px;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: var(--dark-text);
}

.payment input {
  width: 170px;
  margin-bottom: 6px;
}

@media (max-width: 470px) {
  .pricePaidPeriod {
    margin-right: 53px;
  }
}
