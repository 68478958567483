.additionalLinks {
  margin-top: 48px;
}

.title {
  margin-bottom: 12px;
}

.text {
  max-width: 564px;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
}

@media (max-width: 768px) {
  .additionalLinks button {
    margin-bottom: 60px !important;
  }
}

@media (max-width: 481px) {
  .additionalLinks button {
    margin: auto;
  }
}

@media (max-width: 395px) {
  .additionalLinks button {
    margin-bottom: 60px !important;
  }
}
