.mg-input-time__wrap .ant-picker {
  width: 100%;
}

.mg-input-time__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 400px;
}

.ant-picker.mg-input-time {
    position: relative;
    border: none;
}

.ant-picker.mg-input-time .ant-picker-suffix {
    display: none;
}

.mg-input-time__icon {
    position: absolute;
    top: 50%;
    z-index: 2;
    left: 0;
    transform: translateY(-50%);
}

.mg-input-time .ant-picker-input > input {
    font-family: 'Dubai';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 0.06em;
    line-height: 24px;
    padding-left: 33px;
    color: var(--green-text);
    cursor: pointer;
}

.mg-input-time .ant-picker-input > input::placeholder {
    color:  var(--gray-input-placeholder);
    letter-spacing: 1;
}

.ant-picker-focused {
    box-shadow: none;
}

.mg-input-time__dropdown {
  top: 30px !important;
}

.mg-input-time__dropdown .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: var(--green-text);
}

.mg-input-time__dropdown .ant-picker-today-btn {
    color: var(--green-text);
}

.mg-input-time__dropdown .ant-picker-header-view button:hover {
    color: var(--green-text);
}
