.header {
  display: flex;
  margin-bottom: 12px;
}

.header span {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: var(--title-text-blue);
}

.field label {
  margin-right: 50px;
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
}
