.select-algorithm__tabs .ant-tabs-nav-wrap {
  justify-content: center;
}

.select-algorithm__tabs .ant-tabs {
  width: 100%;
}

.select-algorithm__tabs .ant-tabs-content-holder {
  font-family: Dubai;
  font-size: 18px;
  line-height: 24px;
  text-align: left;
  color: var(--light-text);
}

.select-algorithm__tabs .ant-tabs-ink-bar {
  background: var(--dark-text);
}

.select-algorithm__tabs .ant-tabs-tab {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-light-gray);
  padding: 6px 36px;
  border-radius: 4px 4px 0px 0px;
  letter-spacing: 0.06em;
  margin: 0;
}

.select-algorithm__tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
}

.select-algorithm__tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--dark-text);
}

.select-algorithm__tabs .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--gray-bg);
}

.select-algorithm__tabs .ant-tabs-content-holder {
  position: relative;
}

.select-algorithm__tabs .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 4px;
}
