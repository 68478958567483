.selectAlgorithmModal td:first-child {
  padding-right: 10px;
  text-align: left;
}

.selectAlgorithmModal input {
  width: 85px;
}

.selectAlgorithmModal p {
  margin: 10px 0 30px;
}

.field{
  border: 1px solid rgba(0,0,0, 0.2);
  grid-area: 1 / 1; 
  margin: 0;
}

.legendContainer{
  width: 220px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
  text-align: left;
  margin: 0 0 0 320px;
}

.legend {
  color: var(--green-text);
}

.legend:hover{
  color: var(--text-light-blue)
}

.parametersInputWrapper td {
  padding: 10px 5px 10px 5px;
  text-align: left;
}

.parametersInputWrapper td > * {
  margin-bottom: 0;
}


.tableWrapper {
  padding: 10px;
  background-color: var(--gray-bg);
}

.tableWrapper tr:first-child td {
  padding-bottom: 20px;
}

.tableWrapper td:first-child {
  font-weight: bold;
}

.tableWrapper td > * {
  margin-bottom: 0;
}
