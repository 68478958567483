.inline {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
}
.inlineCustom{
    width: 100%;
}
.element {
    width: 100%;
    margin: 5px;
}
.elementCustom25{
    float: left;
    width: 25%;
}
.elementCustom75{
    float: left;
    width: 75%;
}
.tutorialText{
    padding-left: 5px;
    font-size: 16px;
    font-weight: normal;
    text-align: justify;
}

.tutorialTextCentered{
    text-align: center;
}

.video {
    border: 1px solid rgba(0,0,0, 0.1);
    padding: 0 1px;
}