.inline {
    display: flex;
    flex-direction: row;
    height: auto !important;
    width: 100% !important;
}

.elem {
    width: 100%;
}

.closeBtn {
  display: flex;
  margin-left: auto;
  width: 150px;
  font-size: 16px;
  font-family: 'Dubai', sans-serif;
  color: var(--green-text)
}

.closeBtn:hover{
  cursor: pointer;
  color: var(--dark-text);
  transition: color 0.3s;
}