.wrapHeader {
  background-color: var(--gray-bg);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 24px 106px;
}

.headerLeftPart {
  display: flex;
  flex-wrap: wrap;
  margin-right: 24px;
}

.backBtn {
  width: fit-content;
  padding: 0;
  margin-right: 36px;
  color: var(--green) !important;
  font-size: 12px;
  line-height: 24px;
  box-shadow: none;
}

.backBtn svg {
  margin-right: 10px;
  fill: var(--green);
  transition: fill .25s ease;
}

.backBtn:hover {
  color: var(--dark-text) !important;
  font-size: 12px;
  line-height: 24px;
}

.backBtn:hover svg {
  fill: var(--dark-text);
}

.titleSub {
  margin-right: 36px;
  color: var(--dark-text);
  font-size: 20px;
  line-height: 36px;
}

.totalSubscribers {
  color: var(--text-light-gray);
  font-size: 20px;
  line-height: 36px;
}

.content {
  padding: 0 0 48px 0;
}

.prices {
  height: 350px;
  min-width: 480px;
  margin-bottom: 72px;
  margin-right: 86px;
}

.addPriceBtn {
  margin-right: 35px;
  margin-bottom: 20px;
  max-width: 288px;
  float: right;
}

.fieldName {
  min-width: 602px;
  margin-bottom: -10px;
}

.searchSubscribers {
  min-width: 530px;
}

.subscribersContent {
  height: 350px;
  margin-top: 24px;
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
  background-color: var(--subscribers-background);
}

.contentWrap {
  display: flex;
  flex-wrap: wrap;
  padding-top: 36px;
  padding: 36px 106px 0 106px;
}

.subscribers {
  padding: 13px 30px 0 0;
}

@media (max-width: 1150px) {
  .totalSubscribers {
    margin-top: 12px;
  }
}

@media (max-width: 992px) {
  .backBtn {
    margin-bottom: 12px;
  }
}

@media (max-width: 768px) {
  .header {
    padding: 24px 53px;
  }
  .contentWrap {
    padding: 36px 0 0 53px;
  }
}

@media (max-width: 640px) {
  .saveBtn {
    top: 25px;
    width: 288px;
  }
  .searchSubscribers {
    margin-top: 30px;
    min-width: 450px;
  }
}

@media (max-width: 560px) {
  .searchSubscribers {
    min-width: 350px;
  }
}

@media (max-width: 450px) {
  .searchSubscribers {
    min-width: 290px;
  }
}

@media (max-width: 395px) {
  .header {
    padding: 24px 20px;
  }
  .contentWrap {
    padding: 36px 0 0 20px;
  }
}

@media (max-width: 340px) {
  .searchSubscribers {
    min-width: 270px;
  }
  .saveBtn {
    width: 270px;
  }
}
