.successfulPaymentModal p {
  padding-top: 36px;
  margin-bottom: 0;
}

.okBtn {
  margin: auto !important;
}

.cancelBtn {
  display: none;
}
