.mg-modal {
  box-shadow: 0px 2px 4px rgba(11, 44, 84, 0.12), 0px 6px 24px rgba(43, 63, 103, 0.32);
  border-radius: 4px;
  border: 2px solid #c5d0dd;
}

.mg-modal._confirm {
  padding: 0;
  box-shadow: 0px 2px 4px rgba(11, 44, 84, 0.12), 0px 6px 24px rgba(43, 63, 103, 0.32);
  border-radius: 4px;
  border: 2px solid #c5d0dd;
}

.mg-modal._confirm .ant-modal-confirm-btns {
  margin: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 36px;
}

.mg-modal._confirm .ant-modal-confirm-content {
  margin: 0;
  margin-left: 0 !important;
  font-weight: normal;
  color: var(--light-text);
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 60px;
}

.mg-modal._confirm .anticon {
  display: none;
}

.mg-modal._confirm .ant-modal-confirm-title {
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
  color: var(--error);
  text-align: center;
  margin-bottom: 24px;
}

.mg-modal._confirm .ant-modal-confirm-body {
  padding-top: 24px;
}

.mg-modal._confirm.ant-modal-content {
  height: 100%;
}

.mg-modal .ant-modal-header {
  border-bottom: none;
  padding: 24px;
}

.mg-modal .ant-modal-footer {
  border-top: none;
  padding: 36px;
  display: flex;
  justify-content: space-between;
}

.mg-modal .ant-modal-footer .mg-btn {
  max-width: 170px;
}

.mg-modal .ant-modal-body {
  padding: 0 36px;
  font-family: Dubai;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--light-text);
}

.mg-modal._confirm .ant-modal-body {
  padding: 0 36px;
}

.mg-modal .ant-modal-header .ant-modal-title {
  border-bottom: none;
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
  color: var(--text);
  text-align: center;
}

.mg-modal._confirm .ant-modal-header .ant-modal-title {
  color: var(--error);
}

.mg-modal._yellow .ant-modal-header {
  background-color: var(--yellow);
  color: white;
  padding-bottom: 10px;
}

.mg-modal._yellow .ant-modal-title {
  color: white;
}

.mg-modal._red .ant-modal-header {
  background-color: var(--error);
  padding-bottom: 10px;
}

.mg-modal._red .ant-modal-title,
.mg-modal._yellow .ant-modal-title {
  color: white;
}

.mg-modal._info .ant-modal-footer {
  justify-content: center;
}

@media (max-width: 395px) {
  .mg-modal .ant-modal-footer {
    flex-direction: column;
    align-items: center;
    padding-top: 0;
  }

  .mg-modal .ant-modal-footer .mg-btn {
    margin-left: 0 !important;
    margin-top: 18px;
  }
}
