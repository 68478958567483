.alert {
  margin-top: 48px;
}

.title {
  margin-bottom: 12px;
}

.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 55px;
}

.info {
  display: flex;
  align-items: center;
  font-weight: normal;
  flex-wrap: wrap;
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
}

.info div {
  margin-right: 48px;
  margin-bottom: 24px;
}

.info div span:first-child {
  margin-right: 12px;
}

.highlightedText {
  color: var(--dark-text);
}

@media (max-width: 576px) {
  .info {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }

  .content {
    padding-right: 0;
  }
}

@media (max-width: 481px) {
  .content button {
    margin: auto;
  }
}
