.tabsWrap {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.tabsText {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    margin: 0;
    position: absolute;
    top: 12px;
    left: 106px;
    text-transform: uppercase;
    color: var(--text-light-gray);
    letter-spacing: 0.06em;
}

.dividerTabs {
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    min-width: calc(100% - 212px);
}

.groupName {
    align-items: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    color: var(--title-text-blue);
    line-height: 24px;
    margin: 0 0 27px 0;
}

.subGroupName {
    color: var(--title-text-blue);
    line-height: 18px;
    font-size: 14px;
    margin: 0 0 27px 0;
    text-transform: capitalize;
}

.label {
    display: block;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--light-text);
    margin-right: 20px;
    margin-bottom: 10px;
}

.formItemWrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 60%;
}

.formItem {
    margin: 0 0 24px 0;
    min-width: 400px;
}

.fieldsGroup {
    margin-bottom: 70px;
}

.saveSettingBtn {
    margin-top: 50px;
}

.subGroup {
    margin-left: 20px;
}

@media (max-width: 768px) {
  .saveSettingBtn {
    margin-top: 0;
    margin-bottom: 24px;
  }
}

@media (max-width: 576px) {
  .fieldsGroup {
    margin-bottom: 0;
  }
  .subGroup {
    margin-left: 0;
  }
  .formItem {
    min-width: 300px;
  }
}

@media (max-width: 420px) {
  .formItem {
    min-width: 270px;
  }
}
