.paddings {
  padding: 60px 120px 0 120px;
}

.title {
  display: flex;
}

.title h1 {
  color: var(--text-light-blue);
}

.titleInfoBlock {
  margin-right: 6px;
  font-weight: 500;
  letter-spacing: 0.06em;
  color: var(--text-light-gray);
}

.logo {
  margin-left: 27px;
  margin-top: -20px;
}

.info {
  display: flex;
  margin-top: 6px;
}

.infoBlock {
  display: flex;
  margin-right: 36px;
  font-size: 12px;
  line-height: 24px;
  text-transform: uppercase;
}

.count {
  font-weight: bold;
  color: var(--light-text);
}

hr {
  width: 100%;
  margin: 0 0 24px 0;
  border: 0;
  height: 1px;
  background-color: var(--text-light-gray);
}

.remainBlock span {
  color: var(--text-light-blue)!important;
}

@media (max-width: 768px) {
  .paddings {
    padding: 30px 60px 0 60px;
  }
}

@media (max-width: 576px) {
  .logo {
    display: none;
  }
}

@media (max-width: 490px) {
  .info {
    flex-direction: column;
  }
}

@media (max-width: 395px) {
  .paddings {
    padding: 30px 20px 0 20px;
  }
}
