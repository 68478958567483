.form {
  display: flex;
  flex-direction: column;
  max-width: 265px;
  padding: 0;
  border: none;
  border-radius: 7px;
  background-color: #fff;
}

.formItem {
  min-width: 265px;
}

.googleLink,
.facebookLink {
  margin-top: 20px;
}

.googleLink svg,
.facebookLink svg {
  transition: fill .25s ease;
  fill: var(--light-text);
}

.googleLink:hover svg,
.facebookLink:hover svg {
  fill: var(--dark-text);
}

.buttonsWrap {
  display: flex;
  align-items: center;
  color: var(--gray-input-placeholder);
  margin-top: -18px;
  text-transform: none;
  font-size: 18px;
  line-height: 36px;
}

.buttonsWrap .googleLink {
  margin-right: 44px;
}

.buttonsWrap a {
  padding: 0;
}

.buttonsWrap span {
  margin-right: 28px;
}
