.parametersInputWrapper input {
  width: 85px;
}

.field{
  border: 1px solid rgba(0,0,0, 0.2);
  grid-area: 1 / 1; 
  margin: 0;
}

.legendContainer{
  width: 220px;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 0;
  text-align: left;
  margin: 0 0 0 320px;
}

.legend {
  color: var(--green-text);
}

.legend:hover{
  color: var(--text-light-blue)
}

.appInputDiscountRate{
  margin: 10px;
}

.parametersInputWrapper td {
  padding: 10px 5px 10px 5px;
  /* padding-right: 10px;
  padding-bottom: 20px; */
  text-align: left;
}

.parametersInputWrapper td > * {
  margin-bottom: 0;
}

.sliderWrapper {
  margin-top: 20px;
}

.appraisalRateValues {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.appraisalRateValues > div {
  display: flex;
  align-items: center;
  gap: 10px;
}

.appraisalRateValues input {
  width: 80px;
}
