.wrap {
  display: flex;
  align-items: flex-start;
}

.wrap img {
  padding-top: 6px;
}

.content {
  margin-left: 40px;
}

.content h2 {
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
  color: var(--text-bright-green);
}

.content p {
  width: 396px;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
}

@media (max-width: 576px) {
  .content {
    margin-left: 0;
  }
  .wrap img {
    display: none;
  }
  .wrap h2 {
    margin: 0;
    font-size: 24px;
    line-height: 30px;
  }
}
