.privacyPolicy {
  font-weight: normal;
  color: var(--text);
}

.header {
  margin-bottom: 24px;
}

.businessPurpose {
  margin-bottom: 36px;
}
