.page {
  display: flex;
  flex-direction: column;
  padding: 60px 106px 120px 106px;
}

.page__title {
  color: var(--green-text);
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
}

.page__title-uppercase {
  color: var(--green-text);
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
  text-transform: uppercase;
}
.page__title-uppercase._normal {
  font-weight: 400;
}

.page__title-uppercase._green {
  color: var(--text-dark-green);
}

.page__paragraph {
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
}

.page__subtitle {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: var(--text-light-gray);
}

.mg-custom-tooltip {
  position: absolute;
  top: -110%;
  right: 0;
  z-index: 50;
}

.mg-tooltip .ant-tooltip-inner,
.mg-custom-tooltip .tooltip-inner {
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: var(--tooltip-color);
  background-color: #fff;
  border: 2px solid #5fb7d2;
  box-shadow: 0px 2px 6px rgba(43, 63, 103, 0.32);
  border-radius: 4px;
}

.mg-tooltip .ant-tooltip-arrow,
.mg-custom-tooltip .tooltip-arrow {
  display: none;
}

.mg-custom-tooltip .tooltip-inner {
  padding: 5px 8px;
}

@media (max-width: 768px) {
  .page {
    padding: 60px 53px 60px 53px;
  }
}

@media (max-width: 395px) {
  .page {
    padding: 60px 20px 30px 20px;
  }
}
