.priceForm .formItem .mg-select__wrap,
.priceForm .formItem .mg-input__wrap {
  flex-direction: row;
  align-items: center;
}

.priceForm .formItem .mg-select__wrap label,
.priceForm .formItem .mg-input__wrap label {
  min-width: 110px;
}

.priceForm .mg-input-number,
.priceForm .mg-select {
  min-width: 288px;
}

.priceForm .ant-form-item-explain-error {
  max-width: 450px;
}

.priceForm .mg-select-arrow {
  right: -30px;
}

.editTariffForm .mg-input__wrap {
  max-width: 602px;
}

@media (max-width: 560px) {
  .priceForm .formItem .mg-select__wrap,
  .priceForm .formItem .mg-input__wrap {
    flex-wrap: wrap;
    width: 288px;
  }
  .priceForm .mg-select-arrow {
    right: 12px;
  }
}

@media (max-width: 340px) {
  .priceForm .mg-input-number,
  .priceForm .mg-select {
    width: 270px;
    min-width: 270px;
  }
  .priceForm .mg-select-arrow {
    right: 30px;
  }
}
