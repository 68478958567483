.successIcon {
  padding-top: 6px;
  margin-right: 40px;
}

.content h2 {
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
  color: var(--text-bright-green);
}

.content p {
  max-width: 396px;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
}
