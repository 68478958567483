.supportForm {
    flex: 1;
    min-width: none;
  }
  
  .formItem .mg-select__wrap {
    max-width: 100% !important;
  }

  .companyData {
      margin: 0;
      padding-left: 0;
      margin-bottom: 20px;
  }
  
  .companyData li {
      list-style: none;
      text-align: start;
  }