@font-face {
  font-family: 'Dubai';
  font-style: normal;
  font-weight: normal;
  src: local('Dubai'), url('./assets/fonts/dubai/Dubai-Regular.woff') format('woff');
}


@font-face {
  font-family: 'Dubai';
  font-style: normal;
  font-weight: 300;
  src: local('Dubai Light'), url('./assets/fonts/dubai/Dubai-Light.woff') format('woff');
}


@font-face {
  font-family: 'Dubai';
  font-style: normal;
  font-weight: 500;
  src: local('Dubai'), url('./assets/fonts/dubai/Dubai-Medium.woff') format('woff');
}


@font-face {
  font-family: 'Dubai';
  font-style: normal;
  font-weight: 700;
  src: local('Dubai'), url('./assets/fonts/dubai/Dubai-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Constantia Italic';
  font-style: italic;
  font-weight: normal;
  src: local('Constantia-Italic'), url('./assets/fonts/constantia/Constantia-Italic.woff') format('woff');
}

body {
  margin: 0;
  font-family: 'Dubai', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  white-space: pre-line;
}
