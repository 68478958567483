.parametersInputWrapper td {
  padding: 10px 5px 10px 5px;
  align-items: left;
}

.parametersInputWrapper td > * {
  margin-bottom: 0;
}

.parametersInputWrapper input {
  width: 85px;
}
