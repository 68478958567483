.floatingWindow {
  position: sticky;
  z-index: 3;
  top: 160px;
  display: flex;
  gap: 20px;
  margin-top: -25px;
  margin-left: auto;
  padding: 10px;
  width: 325px;
  border-radius: 4px;
  background-color: var(--gray-bg);
  box-shadow: 0px 2px 6px rgba(43, 63, 103, 0.32);
}

.advancedFloatingWindow {
  position: sticky;
  z-index: 3;
  top: 60px;
  display: flex;
  gap: 20px;
  margin-top: -15px;
  margin-right: auto;
  margin-left: 15px;
  padding:  0px 10px;
  width: 700px;
  border-radius: 4px;
  background-color: var(--gray-bg);
  box-shadow: 0px 2px 6px rgba(43, 63, 103, 0.32);
}

.floatingWindow td, .advancedFloatingWindow td {
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 18px;
}

.floatingWindow td:first-child, .advancedFloatingWindow td:first-child{
  padding-right: 30px;
  text-align: left;
}


.floatingWindow td:last-child, .advancedFloatingWindow td:last-child {
  font-weight: bold;
  vertical-align: baseline;
}

.label{
  padding-right: 30px;
  text-align: left;
}


.value {
  font-weight: bold;
  vertical-align: baseline;
}
