.yellowText {
    font-weight: 500;
    font-size: 12px;
    line-height: 24px;
    text-transform: uppercase;
    letter-spacing: 0.06em;
    margin: 0;
    color: var(--yellow);
}

.divider {
    background-color: var(--divider-background);
    margin-top: 0;
    margin-bottom: 24px;
}

.title {
    font-weight: 300;
    font-size: 36px;
    line-height: 48px;
    margin: 0;
    color: var(--text);
}

.wrap {
    margin-bottom: 60px;
}

.companies {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.company {
    display: flex;
    width: 100%;
}

.company>span {
    display: flex;
    padding: 12px 24px;
    background-color: var(--gray-bg);
    height: 48px;
    display: block;
    color: var(--dark-text);
    font-weight: normal;
    font-size: 18px;
    width: 100%;
    line-height: 24px;
}

.setBtn {
    background-color: var(--gray-bg)!important;
    color: var(--green-text)!important;
    height: 48px;
    max-width: 160px;
    width: 20%;
    margin-left: 4px;
}

.titleWrap {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    margin-top: 48px;
}

.titleWrap .cleanBtn {
    display: flex;
    align-items: center;
    margin-left: 36px;
    border: 1px solid var(--green-text);
    color: var(--green-text);
    background-color: white;
    max-width: 182px;
    height: 36px;
    transition: color .25s ease, border-color .25s ease;
}

.titleWrap .cleanBtn svg {
    margin-right: 8px;
    transition: fill .25s ease;
    fill: var(--green-text);
}

.titleWrap .cleanBtn:hover svg {
    fill: var(--dark-text);
}

.titleWrap .cleanBtn:hover {
    color: var(--dark-text);
    border-color: var(--dark-text);
}

@media (max-width: 650px) {
  .titleWrap {
    flex-wrap: wrap !important;
  }
  .title {
    width: 100%;
  }
  .cleanBtn {
    margin-left: 0 !important;
  }
  .setBtn {
    width: 200px !important;
  }
}

@media (max-width: 510px) {
  .company {
    flex-wrap: wrap !important;
  }
  .setBtn {
    margin-left: 0 !important;
    margin-top: 8px !important;
  }
}
