.topPanel:before {
  top: 54px;
  height: 69px;
}

@media (max-width: 692px) {
  .topPanel:before {
    top: 90px;
  }
}

@media (max-width: 644px) {
  .topPanel:before {
    height: 81px;
  }
}
