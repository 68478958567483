.promo {
  padding: 204px 106px 100px 106px;
  background-image: url("src/assets/img/text-background.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.page {
  font-weight: normal;
  font-size: 24px;
  line-height: 36px;
  padding: 0 420px 0 106px;
  color: var(--text);
  background-color: white;
}

.promo h1 {
  max-width: 1100px;
  font-weight: 300;
  font-size: 60px;
  line-height: 56px;
  text-transform: uppercase;
  color: var(--black);
}

.promo p {
  margin: 24px 0 0 0;
  max-width: 610px;
  font-weight: 300;
  line-height: 28px;
  font-size: 24px;
  color: var(--text);
}

.lightGreenTitle {
  color: var(--text-light-green-title);
  font-weight: 300;
  font-size: 36px;
  line-height: 48px;
  padding-top: 72px;
  margin-top: 0;
  margin-bottom: 36px;
}

.noBottomMargin {
  margin-bottom: 0;
}

.lightGreenSubTitle {
  font-weight: 300;
  font-size: 24px;
  line-height: 36px;
  color: var(--text-light-green-title);
  margin-top: 12px;
  margin-bottom: 36px;
}

.textBlock p {
  font-size: 18px;
  line-height: 32px;
  color: var(--light-text);
  margin-bottom: 24px;
  margin-top: 0;
}

.textBlock ul {
  margin-left: 35px;
  list-style-type: none;
}

.textBlock ul li {
  padding-left: 18px;
  margin-bottom: 12px;
  position: relative;
  font-size: 18px;
  color: var(--light-text);
  line-height: 32px;
}

.textBlock ul li:before {
  position: absolute;
  left: 0;
  top: 12px;
  width: 6px;
  height: 6px;
  background-color: var(--text-dark-green);
  border-radius: 50%;
  content: '';
}

.textBlock .listStyleNone li:before {
  background-color: white;
}

.textBlock:last-child {
  padding-bottom: 96px;
}

.sideBar {
  position: fixed;
  right: 106px;
}

@media (max-width: 992px) {
  .sideBar {
    display: none;
  }

  .page {
    padding: 0 106px 0 106px;
  }
}

@media (max-width: 768px) {
  .page {
    padding: 30px 53px 60px 53px;
  }

  .lightGreenTitle {
    padding-top: 0;
  }

  .textBlock:last-child {
    padding-bottom: 0;
  }
}

@media (max-width: 576px) {

  .lightGreenTitle,
  .lightGreenSubTitle {
    margin-bottom: 18px;
  }

  .textBlock ul {
    margin-left: 0;
    padding: 0;
  }

  .listStyleNone {
    padding-left: 0;
  }

  .listStyleNone li:before {
    display: none;
  }

  .listStyleNone li {
    padding-left: 0 !important;
  }
}

@media (max-width: 395px) {
  .page {
    padding: 30px 20px 30px 20px;
  }
}
