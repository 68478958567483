.titleModal {
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  margin: 0;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding-top: 48px;
  color: var(--text-light-gray);
}

.spinnerWrap {
  padding-top: 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.portfolioWrap {
  display: flex;
  flex-direction: column;
  max-height: 250px;
  padding-right: 20px;
}

.portfolio {
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  background-color: var(--gray-bg);
  margin-bottom: 6px;
  font-size: 18px;
  line-height: 24px;
  cursor: pointer;
  transition: background-color .25s ease;
  color: var(--light-text);
}

.portfolio:hover {
  background-color: var(--light-gray-2);
}
