.ant-message-notice-content {
  padding: 12px 36px;
  max-width: 444px;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  background-color: rgba(1, 27, 36, 0.7);
  box-shadow: none;
  border-radius: 5px;
}

.ant-message-custom-content {
  display: flex;
  justify-content: center;
}

.ant-message-custom-content svg {
  max-width: 20px;
  margin-right: 14px;
  margin-top: 2px;
}

.ant-message-custom-content span {
  text-align: left;
}
