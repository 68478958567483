.wrapMenu {
  position: sticky;
  top: 96px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 5;
  padding: 6px 106px;
  background-color: var(--gray-bg);
  box-shadow: 0px 2px 6px rgba(43, 63, 103, 0.32);
}
.advancedWrapMenu{
  position: sticky;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  z-index: 4;
  padding: 6px 106px;
  background-color: var(--gray-bg);
  box-shadow: 0px 2px 6px rgba(43, 63, 103, 0.32);
}
.menu {
  display: flex;
  align-items: center;
  width: 100%;
  background-color: var(--gray-bg);
  border-bottom: 0;
}

.menu svg {
  fill: var(--text-gray);
  transition: fill 0.25s ease;
}

.menuItem {
  padding: 0 12px !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
}
.tutorial {
  margin: 0 100px !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
}

.tutorial a, .tutorial span {
  font-size: 14px;
  color: var(--text-light-gray) !important;
  opacity: 0.8;
  box-shadow: none;
}

.menuItem:first-child {
  padding-left: 0 !important;
}

.menuItem:last-child {
  margin-left: auto;
  padding-right: 0 !important;
}

.menuItem::after {
  display: none;
}

.menuItem a, .menuItem span {
  font-size: 14px;
  color: var(--text-light-gray) !important;
  box-shadow: none;
}

.menuItem a:hover, .menuItem span:hover {
  color: var(--dark-text) !important;
}
.addAlertBtn {
  width: fit-content;
  color: var(--text-light-gray) !important;
  padding: 0 0 0 24px;
  margin: 0;
  transition: color 0.25s ease;
}

.addAlertBtn img {
  z-index: 5;
  padding-right: 8px;
}

.addAlertBtn:hover {
  color: var(--dark-text) !important;
}

.selectedAnchor a {
  color: var(--dark-text) !important;
}

.actionsWrapper {
  display: flex;
  flex-shrink: 0;
  gap: 24px;
  padding: 6px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.06em;
  color: var(--text-light-gray);
}

.actionsWrapper span:hover {
  cursor: pointer;
  color: var(--dark-text);
  transition: color 0.3s;
}

@media (max-width: 768px) {
  .wrapMenu {
    padding: 6px 53px;
  }
}

@media (max-width: 395px) {
  .wrapMenu {
    padding: 6px 20px;
  }
}
