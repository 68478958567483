.tabs-tutorial-model-modal .ant-tabs {
    width: 100%;
}
.tabs-tutorial-model-modal .ant-tabs-ink-bar {
background: var(--dark-text);
}

.tabs-tutorial-model-modal .ant-tabs-tab {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-light-gray);
    padding: 6px 36px;
    border-radius: 4px 4px 0px 0px;
    letter-spacing: 0.06em;
}

.tabs-tutorial-model-modal .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
}

.tabs-tutorial-model-modal .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--dark-text);
}

.tabs-tutorial-model-modal .ant-tabs-tab.ant-tabs-tab-active {
    background: var(--gray-bg);
}

.tabs-tutorial-model-modal .ant-tabs-content-holder {
    /* margin-top: 36px; */
    position: relative;
}

.tabs-tutorial-model-modal .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
   height: 4px;
}