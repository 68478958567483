.step {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
  background-color: var(--step-bg);
  border-radius: 50%;
}

.activeStep {
  background-color: white;
  border: 1px solid var(--gray-input-border);
}
