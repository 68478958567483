.title {
  display: flex;
  align-items: center;
  font-weight: 500;
  letter-spacing: 0.06em;
}

.logo {
  margin-left: 28px;
  margin-top: -16px;
}
