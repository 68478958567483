.ant-select-selection-item {
  display: flex;
  align-items: center;
}

.ant-select-dropdown {
  z-index: 10 !important;
}


.mg-select-custom .ant-select-selection-placeholder{
  margin-top: 3px !important;
  color: var(--gray-input-placeholder) !important;
}

.mg-select-custom__wrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 400px;
}

.mg-select-custom .ant-select-selection-item {
  text-align: left;
}

.mg-select-custom__container {
  width: 100%;
}

.mg-select-custom {
  position: relative;
  font-size: 18px;
  line-height: 36px;
  color: var(--text);
  min-width: 100px;
}

.mg-select-custom::placeholder {
  color: var(--gray-input-placeholder);
}

.mg-select-custom:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f7f7fa !important;
  box-shadow: 0px 0.5px 0.5px rgba(43, 63, 103, 0.32) !important;
  height: 36px !important;
  border-radius: 40px !important;
  padding-right: 40px !important;
}

.mg-select-custom:not(.ant-select-customize-input):hover .ant-select-selector {
  border-color: var(--green-text);
}

.ant-select-focused:not(.ant-select-disabled).mg-select-custom:not(.ant-select-customize-input) .ant-select-selector {
  border-color: var(--green-text);
  box-shadow: none;
}

.mg-select-custom-dropdown .ant-select-item-option-selected {
  background-color: white;
  color: var(--dark-text);
}

.mg-select-custom-dropdown {
  min-width: 100px!important;
}