.invitations {
  margin-top: 48px;
}

.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-right: 55px;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
}

.content p {
  max-width: 570px;
  min-width: 278px;
  margin-right: 24px;
}

.invitationsLeft {
  margin-top: 8px;
  margin-right: 24px;
}

.invitationsLeft span:first-child {
  margin-right: 12px;
}

.highlightedText {
  color: var(--dark-text);
}

@media (max-width: 864px) {
  .content p {
    margin-right: 0;
  }
}

@media (max-width: 645px) {
  .content button {
    margin-top: 36px;
  }
}

@media (max-width: 480px) {
  .content {
    padding-right: 0;
  }
  .content button {
    margin: 36px auto 0 auto;
  }
}
