.menu {
    margin: 0;
    list-style-type: none;
    padding: 0;
}

.menu li {
    position: relative;
    padding-left: 12px;
    font-size: 18px;
    line-height: 24px;
    color: var(--text-sidebar);
    cursor: pointer;
    margin-bottom: 12px;
}

.menu li span:hover {
    color: var(--black);
}

.menu li span {
    display: block;
    transition: color .25s ease;
}

.menu li span:not(:last-child) {
   margin-bottom: 12px;
}

.menu li ul {
    margin-left: 30px;
    list-style-type: none;
    padding: 0;
}

.menu li ul li {
    font-size: 16px;
    line-height: 24px;
}

.menu li ul li:before {
    width: 4px;
    height: 4px;
    background-color: var(--text-sidebar);
}

.menu li:before {
    position: absolute;
    left: 0;
    top: 7px;
    width: 6px;
    height: 6px;
    background-color: var(--text-dark-green);
    border-radius: 50%;
    content: '';
}

.selected {
    color: var(--black)!important;
}

.wrap > span {
    color: var(--text-light-gray);
    border-bottom: 1px solid var(--text-light-gray);
    font-weight: 500;
    font-size: 12px;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    line-height: 24px;
    margin-bottom: 24px;
    display: block;
}

.wrap {
    width: auto;
    max-width: 265px;
}