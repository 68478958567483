.wrapHeader {
  background-color: var(--gray-bg);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 106px;
}

.headerLeftPart {
  display: flex;
  flex-wrap: wrap;
}

.backBtn {
  width: fit-content;
  padding: 0;
  margin-right: 36px;
  color: var(--green) !important;
  font-size: 12px;
  line-height: 24px;
  box-shadow: none;
}

.backBtn svg {
  margin-right: 10px;
  fill: var(--green);
  transition: fill .25s ease;
}

.backBtn:hover {
  color: var(--dark-text) !important;
  font-size: 12px;
  line-height: 24px;
}

.backBtn:hover svg {
  fill: var(--dark-text);
}

.titleSub {
  margin-right: 36px;
  color: var(--dark-text);
  font-size: 20px;
  line-height: 36px;
}

.content {
  width: 100%;
  padding: 0 0 48px 106px;
}

.prices {
  height: 350px;
  min-width: 480px;
  margin-bottom: 72px;
  margin-right: 86px;
}

.contentWrap {
  display: flex;
  padding-top: 36px;
}

@media (max-width: 768px) {
  .header {
    padding: 24px 53px;
  }
  .content {
    padding: 0 0 48px 53px;
  }
}

@media (max-width: 660px) {
  .backBtn {
    margin-bottom: 12px;
  }
}

@media (max-width: 560px) {
  .saveBtn {
    top: 40px;
    width: 288px;
  }
}

@media (max-width: 395px) {
  .header {
    padding: 24px 20px;
  }
  .content {
    padding: 0 0 48px 20px;
  }
}

@media (max-width: 340px) {
  .saveBtn {
    width: 270px;
  }
}
