.title {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.title svg {
  margin-left: 27px;
  margin-bottom: 7px;
}

.text {
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
}

.row {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.requestLink {
  text-decoration: underline;
  cursor: pointer;
}

@media (max-width: 576px) {
  .title svg {
    display: none;
  }
}
