.section {
  display: flex;
  flex-wrap: wrap;
}

.subscriptionsSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 173px;
  border-radius: 4px;
  background-color: var(--gray-bg);
}

.email {
  font-weight: normal;
  font-size: 18px;
  line-height: 36px;
  color: var(--dark-text);
}

.email span {
  margin-right: 139px;
  margin-left: 36px;
}

.aboutSubscription {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: 36px;
}

.aboutSubscription svg {
  margin-right: 24px;
  fill: var(--subscriptions-icons-gray);
}

.section .subscriptionsSection:nth-child(2) {
  flex: 1;
  padding: 36px;
  margin-left: 4px;
}

.nameSubscription {
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
  color: var(--dark-text);
}

.withoutSubscription {
  max-width: 400px;
  margin-bottom: 0;
  margin-right: 99px;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
}

.manageSubscription {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}

.manageSubscription .manageBtn {
  position: absolute;
  top: 18px;
  margin-top: 24px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: var(--green-text);
}

.typeSubscription {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.dateSubscription {
  margin-top: 12px;
  margin-bottom: 0;
  font-size: 18px;
  text-align: center;
}

@media (max-width: 1317px) {
  .aboutSubscription {
    margin-bottom: 0;
  }

  .section .subscriptionsSection:nth-child(2) {
    flex-direction: column;
    padding-bottom: 54px;
  }

  .manageSubscription {
    margin-top: 24px;
  }

  .withoutSubscription {
    margin: 0 auto;
  }

  .typeSubscription {
    justify-content: center;
  }
}

@media (max-width: 937px) {
  .section {
    flex-direction: column;
  }

  .aboutSubscription {
    margin-right: 0;
  }

  .email {
    min-height: 108px;
    margin-bottom: 4px;
    justify-content: center;
  }

  .email span {
    margin: auto;
  }

  .section .subscriptionsSection:nth-child(2) {
    margin-left: 0;
  }
}

@media (max-width: 576px) {
  .aboutSubscription {
    flex-direction: column;
    align-items: center;
  }

  .typeSubscription {
    flex-direction: column;
  }

  .aboutSubscription svg {
    width: 52px;
    margin-right: 0;
  }

  .email span {
    margin-right: 0;
    margin-left: 0;
  }
}

@media (max-width: 474px) {
  .manageSubscription button {
    padding: 0 15px;
  }

  .section .subscriptionsSection:nth-child(2) {
    padding: 30px 20px 70px 20px;
  }
}
