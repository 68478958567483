.topPanel .mg-input__wrap {
  margin-bottom: 0;
  min-width: 250px;
  max-width: 350px;
  flex: 1;
}

.topPanel .mg-btn {
  width: auto;
}

.poll .mg-input__wrap {
  width: 340px;
}

@media (max-width: 684px) {
  .topPanel .mg-input__wrap {
    max-width: none;
  }
}
