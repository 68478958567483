.addFilterButton {
  margin: 0;
  padding: 0;
  margin-right: 39px;
  z-index: 10;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  width: auto;
  border: none;
  box-shadow: none;
  color: var(--table-icons) !important;
}

.addFilterButton:hover {
  color: var(--dark-text) !important;
}

.addFilterButton:hover svg {
  fill: var(--dark-text);
}

.addFilterButton svg {
  fill: var(--table-icons);
  transition: fill .25s ease;
  margin-right: 7px;
}

.rangeWrap {
  position: relative;
  display: flex;
  max-width: 80%;
  justify-content: space-between;
}

.rangeWrapPercent {
  position: relative;
  display: flex;
  max-width: 80%;
  justify-content: space-between;
  margin-left: 85px;
}

.filterWrap {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.filterWrapQuality {
  display: block;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}

.filterTitle {
  display: block;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
  color: var(--light-text);
  margin-right: 20px;
}

.filterRow {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
}

.textInput {
  min-width: 500px;
  margin-bottom: 0;
}
.input{
  margin-bottom: 0;
}
.span{
  padding-right: 5px;
  opacity: 0.6;
}
.span:nth-child(4){
  padding-left: 15px;
}
.inputNumber{
  margin-bottom: 0;
}
.inputWrap {
  display: flex;
  justify-content: flex-start;
  flex-grow: 1;
}
.inputOpt{
  height: 24px;
  overflow: hidden;
}

.rangeInput {
  display: flex;
  align-items: center;
}

.rangeInput:first-child {
  margin-right: 10px;
}

.rangeInput>span {
  margin-right: 6px;
}

.rangeInputNumber {
  width: min-content;
  min-width: 100px;
}

.rangeInputNumber div {
  margin-bottom: 0;
  margin-right: 7px;
}

.selectedIndustryLabel {
  min-width: 500px;
  margin-bottom: 0;
}

.selectedIndustryLabel input {
  cursor: pointer;
}

.filterError {
  position: absolute;
  bottom: 5px;
  left: 0;
  color: var(--error);
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.rangeError {
  padding-bottom: 20px;
}

.removeFilterButton {
  width: auto;
  border: none;
  box-shadow: none;
}

.removeFilterButtonGroupItem {
  right: 60px;
}

.removeFilterButton svg {
  fill: var(--table-icons);
  transition: fill .25s ease;
}

.removeFilterButton:hover svg {
  fill: var(--error);
}

.typeCol {
  min-width: 150px;
  padding-left: 12px;
  color: #5F7794;
}
.selectOpt{
  margin: auto;
}

.otherBtn {
  padding: 0 0 0 20px;
  min-height: unset;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  width: auto;
  border: none;
  box-shadow: none;
  color: var(--green-text) !important;
  z-index: 2;
}

.otherBtn:hover {
  min-height: unset;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  width: auto;
  color: var(--dark-text) !important;
}

.otherBtn:hover svg {
  fill: var(--dark-text) !important;
}

.otherBtn svg {
  fill: var(--green-text);
  transition: fill .25s ease;
  margin-right: 14px;
}

.otherBtn::after {
  display: none !important;
}
