.mg-input__wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 400px;
    margin-bottom: 10px;
}

.mg-input__wrap.required::after {
  content: '*';
  position: absolute;
  right: -10px;
  color: var(--light-text);
}

.mg-input {
    font-size: 18px;
    line-height: 36px;
    border: 1px solid var(--gray-input-border);
    height: 36px;
    padding: 6px 12px;
    color: var(--text);
    border-radius: 4px;
}

.mg-input::placeholder {
    color:  var(--gray-input-placeholder);
}

.mg-input:hover {
    border-color: var(--green-text);
    caret-color: var(--green-text);
}

.mg-input:focus {
    border-color: var(--green-text);
    caret-color: var(--green-text);
    box-shadow: none;
}

.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).mg-input {
    border-color: var(--error);
    box-shadow: none;
}

.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).mg-input:hover {
    border-color: var(--error);
    box-shadow: none;
}

.ant-form-item-has-error :not(.ant-input-disabled):not(.ant-input-borderless).mg-input:focus {
    border-color: var(--error);
    caret-color: var(--green-text);
    box-shadow: none;
}

.ant-form-item-explain-error {
    color: var(--error);
    text-align: right;
    max-width: 400px;
    text-transform: none;
    font-size: 14px;
    line-height: 24px;
}
