.__password {
  box-shadow: none;
}

.__password input {
  font-size: 18px;
  line-height: 36px;
  color: var(--text);
}

.__password input::placeholder {
  color:  var(--gray-input-placeholder);
}

.mg-input.__password:hover {
  border-color: var(--green-text);
  caret-color: var(--green-text);
}

.mg-input.__password:focus {
  border-color: var(--green-text);
  caret-color: var(--green-text);
  box-shadow: none;
}
