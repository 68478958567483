.form {
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 265px;
}

.formItem {
  max-width: 265px;
}

@media (max-width: 360px) {
  .form {
    max-width: 265px;
    min-width: 220px;
  }
  .formItem {
    min-width: 220px;
  }
}
