.wrapPrice {
  display: flex;
  justify-content: space-between;
}

.addPriceBtn {
  width: 110px;
}

.addPriceBtn:hover {
  color: #fff !important;
  background: var(--dark-text) !important;
}

.editPriceBtn {
  width: 90px;
  box-shadow: none;
}

.filterPanel {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  background-color: #E9ECEE;
  padding: 9px 106px;
}

.filterPanel:before {
  position: absolute;
  top: 54px;
  left: -106px;
  width: calc(100% + 106px);
  border-bottom: 1px solid var(--chart-line-backgroud) !important;
  height: 69px;
  background-color: var(--gray-bg) !important;
  content: '';
}

.filterPanelLeftPart {
  display: flex;
  flex-wrap: wrap;
}

.filtersLine {
  display: flex;
}

.filtersLineTitle {
  display: block;
  margin-right: 24px;
}

.filterBtn {
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: var(--text-light-gray) !important;
  min-width: unset;
  width: auto;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  padding: 0;
  min-height: auto;
}

.filterBtn:first-child {
  margin-right: 24px;
}

.tabsWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.filterBtn:hover {
  color: var(--dark-text) !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
}

.filterBtnSelected {
  color: var(--dark-text) !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
}

.showLines {
  display: flex;
  align-items: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  color: #8C9FAE;
  z-index: 2;
  cursor: pointer;
  line-height: 24px;
}

.search {
  min-width: 265px;
  height: 30px;
  margin-right: 24px;
}

.addProductBtn {
  width: fit-content;
  padding-left: 0;
  margin-right: 36px;
  font-weight: 500;
  color: var(--green-text) !important;
  border-radius: 0;
  border-right: 1px solid #A6B8C7;
}

.addProductBtn:hover {
  color: var(--dark-text) !important;
  border-right: 1px solid #A6B8C7;
}

.divider {
  background-color: var(--divider-background);
  margin-top: 0;
  margin-bottom: 24px;
}

.dividerTabs {
  position: absolute;
  top: 36px;
  left: 0;
  width: 100%;
  min-width: calc(100% - 212px);
}

.paddings {
  padding: 60px 106px 0 106px;
}

.editDescriptionBtn {
  position: absolute;
  right: 106px;
  z-index: 2;
  padding: 0;
  width: fit-content;
  font-weight: 500;
  color: var(--green-text) !important;
  transition: all .25s ease;
}

.editDescriptionBtn:hover {
  color: var(--dark-text) !important;
}

@media (max-width: 1232px) {
  .filterPanel:before {
    height: 105px;
  }
}

@media (max-width: 950px) {
  .editDescriptionBtn {
    right: 20px;
    top: 40px;
  }
}

@media (max-width: 902px) {
  .filterPanel:before {
    top: 86px;
  }
}

@media (max-width: 768px) {
  .paddings {
    padding: 60px 53px 0 53px;
  }

  .filterPanel {
    padding: 9px 53px;
  }

  .filterPanel:before {
    left: -53px;
    width: calc(100% + 53px);
  }
}

@media (max-width: 620px) {
  .filterPanel:before {
    height: 81px;
    top: 104px;
  }

  .addProductBtn,
  .addProductBtn:hover {
    border-right: 0;
  }

  .showLines {
    margin-top: 18px;
  }
}

@media (max-width: 603px) {
  .filterPanel:before {
    top: 134px;
  }
}

@media (max-width: 395px) {
  .paddings {
    padding: 60px 20px 0 20px;
  }

  .filterPanel {
    padding: 9px 20px;
  }

  .filterPanel:before {
    left: -20px;
    width: calc(100% + 20px);
  }
}
