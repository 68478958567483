.tabs-wrap .ant-tabs>.ant-tabs-nav:before {
    border: none;
}

.tabs-wrap .ant-tabs-nav {
    padding-left: 0;
    margin: 0;
}

.tabs-wrap-modal .ant-tabs-nav {
    padding-left: calc(100% - 415px);
}

.tabs-wrap-modal .ant-tabs-nav-list {
   justify-content: flex-end;
}
.tabs-wrap-modal .ant-tabs {
    width: 100%;
}

.tabs-wrap .ant-tabs-ink-bar {
    background: var(--dark-text);
}

.tabs-wrap .ant-tabs-tab {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-light-gray);
    padding: 6px 36px;
    border-radius: 4px 4px 0px 0px;
    letter-spacing: 0.06em;
    margin: 0;
}

.tabs-wrap .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
}

.tabs-wrap .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--dark-text);
}

.tabs-wrap .ant-tabs-tab.ant-tabs-tab-active {
    background: var(--gray-bg);
}

.tabs-wrap .ant-tabs-content-holder {
    margin-top: 68px;
    position: relative;
}

.tabs-wrap .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
 }

.tabs-wrap-modal .ant-tabs-ink-bar {
    background: var(--dark-text);
}

.tabs-wrap-modal .ant-tabs-tab {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-light-gray);
    padding: 6px 36px;
    border-radius: 4px 4px 0px 0px;
    letter-spacing: 0.06em;
    margin: 0;
}

.tabs-wrap-modal .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
}

.tabs-wrap-modal .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: var(--dark-text);
}

.tabs-wrap-modal .ant-tabs-tab.ant-tabs-tab-active {
    background: var(--gray-bg);
}

.tabs-wrap-modal .ant-tabs-content-holder {
    margin-top: 36px;
    position: relative;
}

.tabs-wrap-modal .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
   height: 4px;
}

.tabs-wrap {
    font-family: 'Dubai';
}

.tabs-wrap .ant-tabs {
    width: 100%;
}

.rc-virtual-list-scrollbar-thumb {
    background: var(--pagination-disabled)!important;
}

.rc-virtual-list-scrollbar {
    width: 4px!important;
}