.termsAndConditions {
  font-weight: normal;
  color: var(--text);
}

.termsList > ol {
  padding-left: 0;
}

.termsList ol {
  counter-reset: section;
  list-style-type: none;
}

.termsList li:before {
  counter-increment: section;
  content: counters(section, ".") " ";
}

.termsList p {
  margin-top: 1em;
}
