.paddings {
  display: flex;
  padding: 0px 0px 0px 106px;
}

.page {
  position: relative;
  background-color: white;
  height: 100%;
}

.spinnerWrap {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 19;
}

.numberWrap {
  text-align: 'right';
  width: '100%';
  margin: 0;
  padding-right: 25px;
}
.tableButtons {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-right: 90px;
}

.otherBtn {
  padding: 0 0 0 20px;
  min-height: unset;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  width: auto;
  border: none;
  box-shadow: none;
  color: var(--green-text) !important;
  z-index: 2;
}

.otherBtn:hover {
  min-height: unset;
  font-weight: bold;
  font-size: 12px;
  line-height: 24px;
  width: auto;
  color: var(--dark-text) !important;
}

.otherBtn:hover svg {
  fill: var(--dark-text) !important;
}

.otherBtn svg {
  fill: var(--green-text);
  transition: fill .25s ease;
  margin-right: 14px;
}

.otherBtn::after {
  display: none !important;
}

.tableButtonsRow{
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: flex-end;
  margin-right: 90px;
}

.companyLink {
  position: relative;
  color: var(--dark-text);
}

.companyLink:hover {
  color: var(--green-text);
}

.companyLink:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}

.industryHover:before {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  content: '';
}

.saveBtn {
  align-self: flex-end;
  margin-top: 24px;
  margin-bottom: 24px;
}

.filterWrap {
  position: relative;
  display: flex;
  flex-direction:row;
}

.addIdeaBtn {
  padding: 0;
  z-index: 10;
  cursor: pointer;
}

.addIdeaBtn::before {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  content: '';
}

.addIdeaBtn svg {
  transition: fill 0.25s ease;
  fill: var(--table-icons);
}

.addIdeaBtn:hover svg {
  fill: var(--dark-text);
}
.deleteBtn {
  margin: 12px 90px 0px auto;
  padding: 0;
  width: min-content;

  font-size: 13px;
  line-height: 24px;
  color: var(--green-text) !important;
  transition: color 0.25s ease;
}

.deleteBtn:hover {
  margin: 12px 90px 0px auto;
  padding: 0;
  width: min-content;

  font-size: 13px;
  line-height: 24px;
  color: var(--dark-text) !important;
}
.title{
  margin: 10px 0px 0px 0px;
  font-size: 28px;
}
.settingsBtn {
  margin: 0 auto 0 106px;
  padding: 0;
  width: min-content;

  font-size: 13px;
  line-height: 24px;
  color: var(--green-text) !important;
  transition: color 0.25s ease;
}

.settingsBtn:hover {
  margin: 0 auto 0 106px;
  padding: 0;
  width: min-content;

  font-size: 13px;
  line-height: 24px;
  color: var(--dark-text) !important;
}

.settingsBtn svg {
  margin-right: 7px;

  fill: var(--green-text);
  transition: fill 0.25s ease;
}

.settingsBtn:hover svg {
  fill: var(--dark-text);
}

@media (max-width: 600px) {
  .settingsBtn {
    padding: 0 24px 7px 0;
  }
  .tableButtonsRow{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-right: 90px;
  }
}

@media (max-width: 576px) {
  .paddings {
    padding: 60px 24px 0 24px;
  }
}
