.form {
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 265px;
}

.formItem {
  max-width: 265px;
}

.btn {
  min-height: 36px;
  max-width: 265px;
}

.formRulesText {
  font-size: 18px;
  line-height: 24px;
  color: var(--light-text);
  margin-bottom: 0;
  margin-left: 36px;
  max-width: 400px;
}

.formWrap {
  display: flex;
}

@media (max-width: 576px) {
  .formWrap {
    flex-wrap: wrap-reverse;
    max-width: 265px;
    margin-top: 24px;
  }
  .form {
    max-width: 265px;
    min-width: 220px;
  }
  .formItem {
    min-width: 220px;
  }
  .formRulesText {
    margin-left: 0;
    margin-bottom: 18px;
  }
}
