.rowsMenuItem {
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  color: var(--green);
}

.rowsMenuItem:hover {
  color: var(--dark-text)!important;
}
