.layout {
    height: 100%;
}

.content {
    padding: '0 50px';
}

.footer {
    text-align: 'center'
}