.formWrap {
    display: flex;
}

.title {
    margin-bottom: 36px;
}

.formRulesText {
    font-size: 18px;
    line-height: 24px;
    color: var(--light-text);
    margin-bottom: 0;
    margin-left: 36px;
    max-width: 400px;
}

.step {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--step-bg);
    border-radius: 50%;
    font-size: 18px;
    line-height: 24px;
    color: var(--light-text);
    width: 48px;
    height: 48px;
    background-color: var(--step-bg);
    position: relative;
}

.activeStep {
    background-color: white;
    border-color: var(--gray-input-border);
}

.step .ant-steps-icon-dot {
    display: none;
}
