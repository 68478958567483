.tabs-admins .ant-tabs>.ant-tabs-nav:before {
  border: none;
}

.tabs-admins .ant-tabs-nav {
  margin: 0 106px 0 106px;
}

.tabs-admins .ant-tabs-ink-bar {
  background: var(--dark-text);
}

.tabs-admins .ant-tabs-tab {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: var(--text-light-gray);
  padding: 6px 36px;
  border-radius: 4px 4px 0px 0px;
  letter-spacing: 0.06em;
  margin: 0;
}

.tabs-admins .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-transform: uppercase;
}

.tabs-admins .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: var(--dark-text);
}

.tabs-admins .ant-tabs-tab.ant-tabs-tab-active {
  background: var(--gray-bg);
}

.tabs-admins .ant-tabs-content-holder {
  position: relative;
}

.tabs-admins .ant-tabs-ink-bar {
  height: 4px !important;
}

.tabs-admins {
  font-family: 'Dubai';
}

.tabs-admins .ant-tabs {
  width: 100%;
}

.tabs-admins .ant-tabs-nav::before {
  border-bottom: 1px solid #8C9FAE !important;
}

.groups .ant-table-container table > thead > tr:first-child th:first-child,
.groups .ant-table-container table > thead > tr:first-child th:nth-child(2) {
  width: 33%;
}

@media (max-width: 768px) {
  .tabs-admins .ant-tabs-nav {
    margin: 0 53px 0 53px;
  }
}

@media (max-width: 395px) {
  .tabs-admins .ant-tabs-nav {
    margin: 0 20px 0 20px;
  }
}
