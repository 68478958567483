.addFilterButton {
    margin: 0;
    padding: 0;
    z-index: 1;
    font-size: 1px;
    width: auto;
    border: none;
    box-shadow: none;
    color: var(--table-icons) !important;
  }
  
  .addFilterButton:hover {
    color: var(--dark-text) !important;
  }
  
  .addFilterButton:hover svg {
    fill: var(--text-dark-green);
  }
  
  .addFilterButton svg {
    fill: var(--table-icons);
    transition: fill .25s ease;
    margin-left: 7px;
    width: 20px;
  }

  .addedFilterButton svg{
    fill: var(--text-dark-green);
  }