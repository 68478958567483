.ant-menu-submenu-popup img {
  display: none;
}

.ant-menu-submenu ul li {
  padding-left: 20px !important;
}

.ant-menu-overflow-item::after {
  display: none;
}

.ant-menu-overflow-item.ant-menu-overflow-item-rest.ant-menu-submenu.ant-menu-submenu-horizontal {
  margin-top: 12px;
}

.ant-menu-overflow-item.ant-menu-overflow-item-rest.ant-menu-submenu.ant-menu-submenu-horizontal:hover svg,
.ant-menu-overflow-item.ant-menu-overflow-item-rest.ant-menu-submenu.ant-menu-submenu-horizontal.ant-menu-submenu-open svg {
  fill: var(--dark-text);
  transition: fill .25s ease;
}

.ant-menu-submenu-popup {
  z-index: 3;
}

.scroll-header .ps__rail-y {
  right: 0 !important;
  left: unset !important;
  width: 8px !important;
  background-color: transparent !important;
}

.scroll-header .ps__thumb-y {
  width: 8px !important;
  background: #C4D3DB !important;
  border-radius: 4px !important;
}

.scroll-header .ps__thumb-x {
  height: 8px !important;
  border-radius: 4px;
  background-color: #C4D3DB !important;
}

.scroll-header .ps__rail-x {
  bottom: 0;
  opacity: 1 !important;
  background-color: transparent !important;
}

.scroll-header.scrollbar-container {
  width: 100%;
}
