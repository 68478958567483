.table-sort-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%) rotate(0);
    right: -24px;
    transition: transform 0.25s ease;
    margin-left: 12px;
    fill: #B5C5D2;
}

.table-sort-down {
    transform: translateY(-50%) rotate(180deg);
}